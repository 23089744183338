import {FunctionComponent} from 'react';
import styles from './Table.module.css';
import {useTranslation} from "react-i18next";


interface TableProps {
  firstRowTitle: string;
  firstRowValue: number;
  secondRowTitle: string;
  secondRowValue: number;
  thirdRowTitle: string;
  thirdRowValue: number;
  fourthRowTitle: string;
  fourthRowValue: number;
  fifthRowTitle: string;
  fifthRowValue: number;
  hasAdvancedPermissions?: boolean;
}

const Table: FunctionComponent<TableProps> = ({
                                                firstRowTitle,
                                                firstRowValue,
                                                secondRowTitle,
                                                secondRowValue,
                                                thirdRowTitle,
                                                thirdRowValue,
                                                fourthRowTitle,
                                                fourthRowValue,
                                                fifthRowTitle,
                                                fifthRowValue,
                                                hasAdvancedPermissions
                                              }) => {
  const {t} = useTranslation("common");


  return (
    <div className={styles.mostVisitedPage}>
      <div className={styles.heading}>
        <div className={styles.userOverview}>{t("ageDistribution")}</div>
      </div>
      <div className={styles.tables}>
        <div className={styles.subHeading}>
          <div className={styles.pageName}>{t("ageGroup")}</div>
          <div className={styles.totalUsers}>{t("distribution")}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.pageName1}>
            <div className={styles.circle}/>
            <div className={styles.requestAQuote}>{firstRowTitle}</div>
          </div>
          <div
            className={`${styles.div} ${
              !hasAdvancedPermissions ? styles.blurWrapper : ""
            }`}
          >
            {firstRowValue}%
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.pageName1}>
            <div className={styles.circle1}/>
            <div className={styles.requestAQuote}>{secondRowTitle}</div>
          </div>
          <div
            className={`${styles.div} ${
              !hasAdvancedPermissions ? styles.blurWrapper : ""
            }`}
          >
            {secondRowValue}%
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.pageName1}>
            <div className={styles.circle2}/>
            <div className={styles.requestAQuote}>{thirdRowTitle}</div>
          </div>
          <div
            className={`${styles.div} ${
              !hasAdvancedPermissions ? styles.blurWrapper : ""
            }`}
          >
            {thirdRowValue}%
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.pageName1}>
            <div className={styles.circle2}/>
            <div className={styles.requestAQuote}>{fourthRowTitle}</div>
          </div>
          <div
            className={`${styles.div} ${
              !hasAdvancedPermissions ? styles.blurWrapper : ""
            }`}
          >
            {fourthRowValue}%
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.pageName1}>
            <div className={styles.circle2}/>
            <div className={styles.requestAQuote}>{fifthRowTitle}</div>
          </div>
          <div
            className={`${styles.div} ${
              !hasAdvancedPermissions ? styles.blurWrapper : ""
            }`}
          >
            {fifthRowValue}%
          </div>
        </div>
      </div>
    </div>);
};

export default Table;
