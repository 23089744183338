import React, {FunctionComponent, useState} from 'react';
import styles from './BusinessPlusAnnual.module.css';
import checkmark from '../../../../assets/svg/checkmark.svg';
import annualDiscount from '../../../../assets/svg/annual-discount.svg';
import soonTag from '../../../../assets/svg/soon-tag.svg';
import ContactModal from "../../../../components/shared/ContactModal/ContactModal";

const SlotContainer:FunctionComponent = () => {
  const [openContactModal, setShowContactModal] = useState(false);

  return (
    <div className={styles.slotContainer}>
      <div className={styles.pricingCard}>
        <div className={styles.cardContent}>
          <div className={styles.pricingHeadear}>
            <div className={styles.freeParent}>
              <b className={styles.free}>{`Business + `}</b>
              <div className={styles.bestForPersonalContainer}>
                <p className={styles.prKompaniQ}>Për kompani që kërkojnë zgjidhje të avancuara dhe postime pa limit</p>
              </div>
            </div>
            <div className={styles.pricingHeadearChild} />
          </div>
          <div className={styles.buttonmailtoErikpeakjobsa} onClick={() => setShowContactModal(true)}>
            <div className={styles.hrefmailtoErikpeakjobsapp}>Kontakto</div>
          </div>
          <div className={styles.pricingFeatures}>
            <div className={styles.whatYouGet}>Të gjitha funksionet e Pro, plus:</div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Postime pa limit për punë ditore</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Postime pa limit për punë sezonale</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Postime pa limit për punë afatgjatë</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Shkarkimi i listës së aplikantëve (CSV, Excel)</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Krijimi i shabllonëve</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Njoftime automatike për aplikantët që nuk i keni pranuar</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Mbështetje me prioritet nga stafi i Peakjobs</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frame}>
        <div className={styles.pakoMEfikase}>Pako më efikase</div>
      </div>
      <div className={styles.parent}>
        <b className={styles.free}>239.99€</b>
        <div className={styles.muaj}>/muaj</div>
      </div>
      <div className={styles.featureRow5}/>
      <div className={styles.featureRow6}/>
      <div className={styles.featureRow7}/>
      <div className={styles.featureRow8}>
        <div className={styles.checkmarkIcon} />
      </div>
      <div className={styles.featureRow11} />
      <div className={styles.slotContainerChild} />
      <div className={styles.kurseni720}>
        <b>Kurseni 720</b>
        <span>€</span>
        <b>{` `}</b>
      </div>
      <img className={styles.slotContainerItem} alt="" src={annualDiscount} />
      {openContactModal && (
        <ContactModal
          phoneNumber={"+38345276300"}
          onClose={() => setShowContactModal(false)}
          isContactSales={true}
        />
      )}
    </div>);
};
export default SlotContainer;