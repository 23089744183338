import {FunctionComponent} from 'react';
import styles from './Basic.module.css';
import checkmark from '../../../assets/svg/checkmark.svg';
import sparkles from '../../../assets/svg/sparkles.svg';
import soonTag from '../../../assets/svg/soon-tag.svg';
import {useNavigate} from "react-router-dom";

const PricingCard: FunctionComponent = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.pricingCard}>
      <div className={styles.cardContent}>
        <div className={styles.pricingHeadear}>
          <div className={styles.freeParent}>
            <b className={styles.free}>Basic</b>
            <div className={styles.bestForPersonalContainer}>
              <p className={styles.idealPrBiznese}>{`Ideal për biznese të vogla `}</p>
              <p className={styles.idealPrBiznese}>që kërkojnë punëtorë me kosto të ulët</p>
            </div>
          </div>
          <div className={styles.parent}>
            <b className={styles.free}>9.99€</b>
            <div className={styles.month}>/postim</div>
          </div>
        </div>
        <div
          className={styles.buttonhttpsbusinesspeakjo}
          onClick={() => {
            navigate("/jobs/new-short-term-job");
          }}
        >
          <div className={styles.hrefhttpsbusinesspeakjobs}>Posto Tani</div>
        </div>
        <div className={styles.pricingFeatures}>
          <div className={styles.whatYouGet}>Çka përfitoni:</div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>Menaxhimi i aplikantëve nga telefoni</div>
          </div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>50,000 punëtorë të verifikuar</div>
          </div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>Postimi qëndron i publikuar 30 ditë</div>
          </div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>Numri i shikimeve dhe numri i klikimeve</div>
          </div>
          <div className={styles.featureRow3}>
            <div className={styles.checkmarkIcon}/>
          </div>

        </div>
      </div>
    </div>);
};

export default PricingCard;
