import React, {useEffect, useState, useRef} from "react";
import {apiCall} from "../../api/Api";
// import SearchUserCard from "../SearchUserCard/SearchUserCard";
import useInfiniteScroll from "react-infinite-scroll-hook";
import LoadingComponent from "../../components/shared/LoadingComponent/LoadingComponent";
import RightSidebar from "../../components/shared/RightSidebar/RightSidebar";
// import SearchUserSidebar from "../SearchUserSidebar/SearchUserSidebar";
import chevronLeft from "../../assets/svg/chevron-down-gray.svg";
import cs from "classnames";
import {useNavigate} from "react-router-dom";
import reloadIcon from "../../assets/svg/Refresh_icon.svg";

import {useTranslation} from "react-i18next";

import "./Search.scss";
import {useOutsideClickDetection} from "../../lib/hooks/useOutSideClickDetection";
import {Box, Chip, debounce, ListItemIcon, MenuItem, Popover, Slider, Stack, TextField} from "@mui/material";
import {ArrowDropDown, Done, Download, FileCopy, LowPriority, Share} from "@mui/icons-material";
import SelectBox from "../../components/shared/SelectBox/SelectBox";
import {useGlobalContext} from "../../context/GlobalContext/GlobalContext";
import {convertRestApiErrorCodes, handleErrors} from "../../lib/helpers/handleErrors";
import {useAuthContext} from "../../context/AuthContext/AuthContext";
import WarningModal from "../../components/shared/WarningModal/WarningModal";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebaseConfig";
import ApplicantSidebar from "../Jobs/Applications/ApplicantSidebar/ApplicantSidebar";
import ApplicationCard from "../Jobs/Applications/ApplicationCard/ApplicationCard";
import InputField from "../../components/shared/InputField/InputField";
import SearchResultSidebar from "./SearchUserSidebar/SearchResultSidebar";
import SearchCard from "./SearchUserCard/SearchCard";
import BusinessPlus from "../Plans/BusinessPlus/BusinessPlus";
import Enterprise from "../Plans/Enterprise/Enterprise";

interface DegreeOption {
  value: string;
  label: string;
}

interface GenderOption {
  value: string;
  label: string;
}

interface AgeRange {
  min: number;
  max: number;
}

const Search = () => {
    let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
    let selectedLanguage = localStorage.getItem("lang");
    const globalCtx = useGlobalContext();
    const authCtx = useAuthContext();
    const [job, setJob] = useState(jobStorage);
    const [allApplications, setAllApplications] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [error] = useState("");
    const [nextPage, setNextPage] = useState<any>("");
    const [isCalled, setIsCalled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openRightSidebar, setOpenSidebar] = useState(false);
    const [selectedApplicant, setSelectedApplicant] = useState<any>({});
    const [refreshCalled, setRefreshCalled] = useState(false);
    const rightSidebarRef = useRef(null);
    const {t} = useTranslation("common");
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const [ageAnchorEl, setAgeAnchorEl] = useState<null | HTMLElement>(null);
    const ageOpen = Boolean(ageAnchorEl);

    const [genderAnchorEl, setGenderAnchorEl] = useState<null | HTMLElement>(null);
    const genderOpen = Boolean(genderAnchorEl);

    const [selectedFilter, setSelectedFilter] = useState<string>('');
    const [showPermissionRequiredModal, setShowPermissionRequiredModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [hasDrivingLicense, setHasDrivingLicense] = useState(false);
    const [personAddress, setPersonAddress] = useState("");
    const [gender, setGender] = useState("");
    const [hasExperience, setHasExperience] = useState(false);
    const [educationStatus, setEducationStatus] = useState("");
    const [ageRange, setAgeRange] = useState<number[]>([16, 65]);

    const degreeOptions: DegreeOption[] = [
      {value: "elementary", label: t("elementarySchoolDegree")},
      {value: "high_school", label: t("highSchoolDegree")},
      {value: "bachelor", label: t("bachelorDegree")},
      {value: "master", label: t("masterDegree")},
      {value: "doctorate", label: t("doctoralDegree")},
    ];

    const ageRangeOptions: AgeRange[] = [
      {min: 16, max: 25},
      {min: 26, max: 35},
      {min: 36, max: 45},
      {min: 46, max: 55},
      {min: 56, max: 65},
    ];

    const genderOptions: GenderOption[] = [
      {value: "M", label: t("male")},
      {value: "F", label: t("female")},
    ];

    useEffect(() => {
      if (showPermissionRequiredModal) {
        logEvent(analytics, "need_to_subscribe_modal_opened", {
          organization: authCtx.localOrganization.name || "unknown",
        });
        logEvent(analytics, "download_csv_button_clicked", {
          organization: authCtx.localOrganization.name || "unknown",
        });
      }
    }, [showPermissionRequiredModal]);

    useEffect(() => {
      setLoading(true);
      refresh();
    }, [selectedFilter]);

    const hideRightSidebar = () => {
      setOpenSidebar(false);
      setSelectedApplicant({});
    };

    const handleClose = () => {
      setAnchorEl(null);
    };


    const handleAgeClose = () => {
      setAgeAnchorEl(null);
    };

    const handleGenderClose = () => {
      setGenderAnchorEl(null);
    }

    const debouncedSearch = debounce((callback) => callback(), 300);

    useEffect(() => {
      debouncedSearch(() => {

        if (searchQuery.length > 4) {
          // generate the url based on search and query params
          let url = `search/users/?q=${searchQuery}&age_min=${ageRange[0]}&age_max=${ageRange[1]}`;
          if (hasDrivingLicense) url = url.concat("&has_driving_license=true");
          if (personAddress.length > 0) url = url.concat(`&address=${personAddress}`);
          if (gender.length > 0) url = url.concat(`&gender=${gender}`);
          if (hasExperience) url = url.concat(`&has_experience=true`);
          if (educationStatus.length > 0) url = url.concat(`&education_degree_status=${educationStatus}`);
          if (gender.length > 0) url = url.concat(`&gender=${gender}`);


          setIsLoading(true);
          apiCall("GET", url).then((res) => {
              setAllApplications(res.data.results);
              setNextPage(res.data?.next);
              setIsCalled(true);
              setIsLoading(false);
              setLoading(false);
            }
          ).catch((err) => {
              setIsLoading(false);
              const errorMessage = convertRestApiErrorCodes(
                err?.response?.data?.code,
                t,
              );
              globalCtx.openMessageModal("error", errorMessage);
            }
          );
        } else if (searchQuery.length === 0) {
          setAllApplications([]);
        }
      });
    }, [searchQuery, hasDrivingLicense, personAddress, gender, hasExperience, educationStatus, ageRange]);


    const refresh = () => {
      if (searchQuery.length > 4) {

        // clear the list
        setAllApplications([]);
        setIsLoading(true);
        let url = `search/users/?q=${searchQuery}&age_min=${ageRange[0]}&age_max=${ageRange[1]}`;
        if (hasDrivingLicense) url = url.concat("&has_driving_license=true");
        if (personAddress.length > 0) url = url.concat(`&address=${personAddress}`);
        if (gender.length > 0) url = url.concat(`&gender=${gender}`);
        if (hasExperience) url = url.concat(`&has_experience=true`);
        if (educationStatus.length > 0) url = url.concat(`&education_degree_status=${educationStatus}`);
        if (gender.length > 0) url = url.concat(`&gender=${gender}`);

        apiCall("GET", url)
          .then((res) => {
            setAllApplications(res.data.results);
            setNextPage(res.data?.next);
            setIsCalled(true);
            setIsLoading(false);
            setLoading(false);
          })
          .catch((err) => {
              setIsLoading(false);
              const errorMessage = convertRestApiErrorCodes(
                err?.response?.data?.code,
                t,
              );
              globalCtx.openMessageModal("error", errorMessage);
            }
          );
      }
    };

    const loadMore = () => {
      if (searchQuery.length > 4) {

        if (nextPage !== null) {
          setIsLoading(true);
          let url = `search/users/?q=${searchQuery}&age_min=${ageRange[0]}&age_max=${ageRange[1]}`;
          if (hasDrivingLicense) url = url.concat("&has_driving_license=true");
          if (personAddress.length > 0) url = url.concat(`&address=${personAddress}`);
          if (gender.length > 0) url = url.concat(`&gender=${gender}`);
          if (hasExperience) url = url.concat(`&has_experience=true`);
          if (educationStatus.length > 0) url = url.concat(`&education_degree_status=${educationStatus}`);
          if (gender.length > 0) url = url.concat(`&gender=${gender}`);

          let resultUrl = nextPage.split("?")[1];
          url = url.concat(`&${resultUrl}`);
          apiCall("GET", url)
            .then((res) => {
              setAllApplications([...allApplications, ...res.data.results]);
              setIsCalled(true);
              setNextPage(res.data?.next);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
            });
        }
      }
    };

    const [sentryRef] = useInfiniteScroll({
      loading,
      hasNextPage: nextPage,
      onLoadMore: loadMore,
      disabled: !!error,
      rootMargin: "0px 0px 400px 0px",
    });

    useOutsideClickDetection(rightSidebarRef, () => {
      hideRightSidebar();
    });

    return (
      <section className="Search">
        <div className="Search__header">
          <div className="Search__title-container">
            <h1 className="Search__default-title">
              {t("search")}
            </h1>
            {isLoading ? (
              <div className="Search__spinner"/>
              // <LoadingComponent/>
            ) : (
              <img
                src={reloadIcon}
                alt="reload icon"
                className={cs(
                  "Search__reload-icon",
                  refreshCalled && "Search__reload-icon--disable",
                )}
                onClick={() => {
                  if (!refreshCalled) {
                    refresh();
                    setRefreshCalled(true);
                    setTimeout(() => {
                      setRefreshCalled(false);
                    }, 10000);
                  }
                }}
              />
            )}
          </div>
          <div className="Search__input">
            <div className="Search__input-row">
              <InputField
                type="text"
                placeholder={t(
                  "searchExample",
                )}
                name="search"
                value={searchQuery}
                onBlur={(e: any) => setSearchQuery(e.target.value)}
                onChange={(e: any) => setSearchQuery(e.target.value)}
                disabled={!authCtx.localOrganization.is_enterprise}
              />
              {searchQuery.length > 4 && (
                <InputField
                  type="text"
                  placeholder={t(
                    "vendbanimi",
                  )}
                  name="vendbanimi"
                  value={personAddress}
                  onBlur={(e: any) => setPersonAddress(e.target.value)}
                  onChange={(e: any) => setPersonAddress(e.target.value)}
                />
              )}
            </div>
            <div className="Search__input-description">
              {t(
                "searchForUsersBy",
              )}
            </div>
          </div>
          <div className="Search__header-chips">
            <Stack direction="row" spacing={1}>
              {ageRange && (
                <Chip
                  key={"ageRange"}
                  label={`${t("ageRange")}: ${ageRange[0]} - ${ageRange[1]} ${t("ageYears")}`}
                  sx={{borderRadius: 2}}
                  onClick={(e) => {
                    setAgeAnchorEl(e.currentTarget as HTMLElement);
                  }}
                  icon={<ArrowDropDown/>}
                  onDelete={ageRange[0] !== 16 || ageRange[1] !== 65 ? () => setAgeRange([16, 65]) : undefined}

                />
              )}

              <Popover
                id="long-menu"
                anchorEl={ageAnchorEl}
                open={ageOpen}
                onClose={handleAgeClose}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: 48 * 4.5,
                      width: 'auto',
                    },
                  },
                }}
              >
                {ageRangeOptions.map((option) => (
                  <MenuItem
                    key={option.min}
                    onClick={() => {
                      setAgeRange([option.min, option.max]);
                      handleAgeClose();
                    }}
                  >
                    {`${option.min} - ${option.max}`}
                  </MenuItem>
                ))}
              </Popover>

              {gender.length === 0 && (
                <Chip
                  key={"gender"}
                  label={t("gender")}
                  icon={<ArrowDropDown/>}
                  clickable
                  onClick={(e) => {
                    setGenderAnchorEl(e.currentTarget as HTMLElement);
                  }}
                  variant={gender ? "filled" : "outlined"}
                  sx={{borderRadius: 2}}
                />
              )}
              {gender && (
                <Chip
                  key={"gender"}
                  label={genderOptions.find((option) => option.value === gender)?.label}
                  onDelete={() => {
                    setGender("");
                  }}
                  sx={{borderRadius: 2}}
                />
              )}


              <Popover
                id="long-menu"
                anchorEl={genderAnchorEl}
                open={genderOpen}
                onClose={handleGenderClose}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: 48 * 4.5,
                      width: 'auto',
                    },
                  },
                }}
              >
                {genderOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => {
                      setGender(option.value);
                      handleGenderClose();
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Popover>

              {educationStatus.length === 0 && (
                <Chip
                  key={"educationLevel"}
                  label={t("educationLevel")}
                  icon={<ArrowDropDown/>}
                  clickable
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget as HTMLElement);
                  }}
                  variant={educationStatus ? "filled" : "outlined"}
                  sx={{borderRadius: 2}}
                />
              )}
              {educationStatus.length > 0 && (
                <Chip
                  key={"educationStatus"}
                  label={degreeOptions.find((option) => option.value === educationStatus)?.label}
                  onDelete={() => {
                    setEducationStatus("");
                  }}
                  sx={{borderRadius: 2}}
                />
              )}

              <Popover
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                  paper: {
                    style: {
                      maxHeight: 48 * 4.5,
                      width: 'auto',
                    },
                  },
                }}
              >
                {degreeOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    onClick={() => {
                      setEducationStatus(option.value);
                      handleClose();
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Popover>

              <Chip
                key={"hasDrivingLicense"}
                label={t("hasDrivingLicense")}
                icon={hasDrivingLicense ? <Done/> : undefined}
                clickable
                onClick={() => {
                  setHasDrivingLicense(!hasDrivingLicense);
                }}
                variant={hasDrivingLicense ? "filled" : "outlined"}
                sx={{borderRadius: 2}}
              />

              <Chip
                key={"hasExperience"}
                label={t("hasExperience")}
                icon={hasExperience ? <Done/> : undefined}
                clickable
                onClick={() => {
                  setHasExperience(!hasExperience);
                }}
                variant={hasExperience ? "filled" : "outlined"}
                sx={{borderRadius: 2}}
              />
            </Stack>
          </div>
        </div>
        {!isLoading && allApplications?.length === 0 && (
          !authCtx.localOrganization.is_enterprise ? (
            <div className="Plans__products__container">
              <p className="JobAnalytics__subscribe-subtitle" onClick={() => navigate("/plans")}>
                {t("subscribeToSearch")}
              </p>
            </div>
          ) : (
            <div className="Search__no-content">
              {t("searchForUsersBy")}
            </div>
          )
        )}
        {isLoading && (
          <div className="Search__no-content">
            <LoadingComponent/>
          </div>
        )}
        {
          isLoading && loading && allApplications?.length === 0 && (
            <div className="Search__no-content">
              <LoadingComponent/>
            </div>
          )}
        <div className="Search__cards-wrapper">
          {allApplications &&
            allApplications.map((person: any, index: number) => (
              <div key={index} className="Applications__card">
                <SearchCard
                  person={person}
                  setSelectedApplicant={setSelectedApplicant}
                  setOpenSidebar={setOpenSidebar}
                  refreshList={refresh}
                />
              </div>
            ))}
        </div>
        <div>
          {nextPage && nextPage !== null && (
            <div ref={sentryRef}>
              <LoadingComponent/>
            </div>
          )}
        </div>
        {isLoading && !loading && allApplications?.length === 0 && (
          <div className="Search__no-content">
            <LoadingComponent/>
          </div>
        )}
        {openRightSidebar && (
          <div>
            <RightSidebar
              onClose={() => hideRightSidebar()}
              sidebarRef={rightSidebarRef}
            >
              <SearchResultSidebar
                person={selectedApplicant}
                onClose={() => hideRightSidebar()}
                loadMore={refresh}
              />
            </RightSidebar>
          </div>
        )}

        {showPermissionRequiredModal && (
          <WarningModal
            title={t("thisFeatureIsAvailableOnlyForSubscribers") + " " + t("contactSalesForSubscription")}
            buttonLabel={t("seePlans")}
            onCancel={() => setShowPermissionRequiredModal(false)}
            onSubmit={() => navigate("/plans")}
            hideCancelBtn={true}
          />
        )}

      </section>
    );
  }
;

export default Search;
