import React, {FunctionComponent} from 'react';
import styles from './TwoCards.module.css';
import {Man, Woman} from "@mui/icons-material";

interface TwoCardsProps {
  firstCardTitle: string;
  firstCardValue: number;
  secondCardTitle: string;
  secondCardValue: number;
  firstCardIcon: any;
  secondCardIcon: any;
  hasAdvancedPermissions?: boolean;
}


const TwoCards: FunctionComponent<TwoCardsProps> = ({
                                                      firstCardTitle,
                                                      firstCardValue,
                                                      secondCardTitle,
                                                      secondCardValue,
                                                      firstCardIcon: FirstCardIcon,
                                                      secondCardIcon: SecondCardIcon,
                                                      hasAdvancedPermissions
                                                    }) => {
  return (
    <div className={styles.cards}>
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.text}>
            <div
              className={`${styles.div} ${
                !hasAdvancedPermissions ? styles.blurWrapper : ""
              }`}
            >
              {firstCardValue}%
            </div>
            <div className={styles.totalUsers}>{firstCardTitle}</div>
          </div>
          <div className={styles.icon}>
          <FirstCardIcon sx={{fontSize: 20}} style={{color: "gray"}}/>
          </div>
        </div>
      </div>
      <div className={styles.divider}/>
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.text}>
            <div
              className={`${styles.div} ${
                !hasAdvancedPermissions ? styles.blurWrapper : ""
              }`}
            >{secondCardValue}%
            </div>
            <div className={styles.totalUsers}>{secondCardTitle}</div>
          </div>
          <div className={styles.icon}>
          <SecondCardIcon sx={{fontSize: 20}} style={{color: "gray"}}/>
          </div>
        </div>
      </div>
    </div>);
};

export default TwoCards;
