import React, {FunctionComponent} from 'react';
import styles from './SingleCard.module.css';
import {Man, Woman} from "@mui/icons-material";

interface SingleCardProps {
  firstCardTitle: string;
  firstCardValue: number;
  firstCardIcon: any;
  hasAdvancedPermissions?: boolean;
}


const SingleCard: FunctionComponent<SingleCardProps> = ({
                                                          firstCardTitle,
                                                          firstCardValue,
                                                          firstCardIcon: FirstCardIcon,
                                                          hasAdvancedPermissions
                                                        }) => {
  return (
    <div className={styles.cards}>
      {/*<div className={styles.divider}/>*/}
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.text}>
            <div
              className={`${styles.div} ${
                !hasAdvancedPermissions ? styles.blurWrapper : ""
              }`}
            >
              {firstCardValue}%
            </div>
            <div className={styles.totalUsers}>{firstCardTitle}</div>
          </div>
          <div className={styles.icon}>
            <FirstCardIcon sx={{fontSize: 20}} style={{color: "gray"}}/>
          </div>
        </div>
      </div>
      {/*<div className={styles.divider}/>*/}
    </div>);
};

export default SingleCard;
