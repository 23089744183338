import React, {useState, useEffect} from "react";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";
import InputField from "../../../components/shared/InputField/InputField";
import {useShortTermFormik} from "./useShortTermFormik";
import TextAreaInput from "../../../components/shared/TextAreaInput/TextAreaInput";
import closeGray from "../../../assets/svg/close-gray.svg";
import Button from "../../../components/shared/Button/Button";
import info from "../../../assets/svg/info.svg";
import SelectBox from "../../../components/shared/SelectBox/SelectBox";
import DropdownSearch from "../../../components/shared/DropdownSearch/DropdownSearch";
import MultiFileUploader from "../../../components/shared/MultiFileUploader/MultiFileUploader";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import deleteIcon from "../../../assets/svg/delete-icon.svg";
import {useTranslation} from "react-i18next";
import {apiCall} from "../../../api/Api";
import {useNavigate, useLocation} from "react-router-dom";
import {useDebounce} from "use-debounce";
import {PhotoProvider, PhotoView} from "react-image-previewer";
import {
  SlideArrowLeft,
  SlideArrowRight,
  CloseButton,
} from "react-image-previewer/ui";
import ShortTermPicker from "../../../components/shared/ShortTermPicker/ShortTermPicker";
import {convertRestApiErrorCodes} from "../../../lib/helpers/handleErrors";
import JobPayment from "../../../components/shared/JobPayment/JobPayment";
import ModalContainer from "../../../components/shared/ModalContainer/ModalContainer";

import "./ShortTermForm.scss";
import ConfirmJobPaymentModal from "../../../components/shared/JobPayment/ConfirmJobPayment";
import NewUnitModal from "../../../components/shared/NewUnitModal/NewUnitModal";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";
import WarningModal from "../../../components/shared/WarningModal/WarningModal";

type datesType = {
  start_time: Date;
  end_time: Date;
};

const ShortTermForm = () => {
  const {t} = useTranslation("common");
  const authCtx = useAuthContext();
  let selectedLanguage = localStorage.getItem("lang");
  const [selectedUnit, setSelectedUnit] = useState<any>({
    name: "",
    value: "",
  });
  const [selectedJob, setSelectedJob] = useState("");
  // const [selectJobItem, setSelectedJobItem] = useState<any>();
  const [shifts, setShifts] = useState<datesType[]>([]);
  // const [jobTitles, setJobTitles] = useState<any>();
  const [businessUnits, setBusinessUnits] = useState<[]>([]);
  const [selectError, setSelectError] = useState({
    name: "",
    active: false,
  });
  //add image state
  const [isImageUploaderOpen, setIsImageUploaderOpen] = useState(false);
  const [base64Images, setBase64Images] = useState<File[]>([]);
  const [limit, setLimit] = useState(10);
  const [offset, setOffSet] = useState(0);
  const [nextPage, setNextPage] = useState<any>("");
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  //DEBAUNCER
  const [filter, setFilter] = useState<string>("");
  const [debouncedFilter] = useDebounce(filter, 500);
  const [isLoaded, setIsLoaded] = useState(false);
  const globalCtx = useGlobalContext();
  const navigate = useNavigate();
  const [showJobPayment, setShowJobPayment] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openNewUnitModal, setOpenNewUnitModal] = useState(false);
  const [isProJob, setIsProJob] = useState(false);
  const [payWithBankTransfer, setPayWithBankTransfer] = useState(false);
  const [showBankTransferModal, setShowBankTransferModal] = useState(false);
  let duplicateFromShortTermJob = JSON.parse(localStorage.getItem("duplicateFromShortTermJob") || "{}");

  useEffect(() => {
    return () => {
      // Clear local storage when leaving the page
      localStorage.removeItem("duplicateFromShortTermJob");
    };
  }, []);


  useEffect(() => {
    // Set shifts from duplicate job if they exist
    if (duplicateFromShortTermJob.shifts) {
      setShifts(duplicateFromShortTermJob.shifts);
    }
  }, []);

  useEffect(() => {
    try {
      apiCall("get", "b2b/organization-unit/").then((res: any) => {
        setBusinessUnits(res.data);
      });
    } catch (err) {
    }
  }, []);

  const refreshUnits = () => {
    try {
      apiCall("get", "b2b/organization-unit/").then((res) => {
        setBusinessUnits(res.data);
      });
    } catch (err) {
    }
  }

  const formik = useShortTermFormik({
    onSubmit: async (values: any) => {
      setIsButtonLoading(true);

      let data = {
        is_pro: isProJob,
        bank_transfer_payment: payWithBankTransfer,
        job_title_text: values.job_title_text,
        // job_title: filterItem ? filterItem[0]?.id : "",
        pay_rate: values.hourly_rate,
        description: values.description,
        organization_unit: selectedUnit?.id,
        image_data: base64Images,
        shifts: shifts,
        latitude: selectedUnit?.latitude,
        longitude: selectedUnit?.longitude,
        seats: values.seats,
      };

      apiCall("POST", `b2b/jobs/?is_pro=${isProJob}&bank_transfer_payment=${payWithBankTransfer}`, data)
        .then((res: any) => {
          if (authCtx.isOrganizationBusinessPlusOrPro) {
            globalCtx.openMessageModal(
              "success",
              t("staticMessage.newJobPostedSuccess")
            );
            navigate("/jobs/short-term-not-assigned");
          } else if (!authCtx.isOrganizationCreatedMonthAgo && !isProJob) {
            globalCtx.openMessageModal(
              "success",
              t("staticMessage.newJobPostedSuccess")
            );
            navigate("/jobs/short-term-not-assigned");
          } else if (payWithBankTransfer) {
            setShowBankTransferModal(true);
          }
          else {
            const clientSecret = res.data.checkout_session.client_secret;
            setClientSecret(clientSecret);
            setShowJobPayment(true); // Show the JobPayment component
          }
        })
        .catch((err: any) => {
          const errorMessage = convertRestApiErrorCodes(
            err?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);

          setIsButtonLoading(false);
        });
    },
  });

  return (
    <>
      {showJobPayment && clientSecret && (
        <ModalContainer position="top">
          <JobPayment
            clientSecret={clientSecret}
            onCloseClick={() => {
              setShowJobPayment(false);
              navigate("/jobs/short-term-not-assigned");
              globalCtx.openMessageModal(
                "warning",
                t("staticMessage.newJobPostedButNotPaid"),
              );
            }
            }
          />
        </ModalContainer>
      )}

      <div className="ShortTermForm">
        <div className="ShortTermForm__header">
          <h1 className="ShortTermForm__default-title">{t("jobs.title")}</h1>
          <div className="ShortTermForm__header-wrapper">
            <img
              alt="chevron left icon"
              src={chevronLeft}
              className="ShortTermForm__back-icon"
              onClick={() => navigate(-1)}
            />
            <h2 className="ShortTermForm__title">
              {t("jobs.newShortTermJob.title")}
            </h2>
          </div>
        </div>
        <div className="ShortTermForm__form">
          <div className="ShortTermForm__column">
            <div className="ShortTermForm__subtitle">
              {t("jobs.newShortTermJob.jobDetails.title")}
            </div>
            <div className="ShortTermForm__input-container">
              <InputField
                type="text"
                placeholder={t(
                  "jobs.newShortTermJob.jobDetails.jobTitle.placeholder",
                )}
                name="job_title_text"
                value={formik.values.job_title_text}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="ShortTermForm__input"
              />
              {formik.errors.job_title_text && formik.touched.job_title_text && (
                <p className="ShortTermForm__input-error">
                  {formik.errors.job_title_text as string}
                </p>
              )}

            </div>
            <div className="ShortTermForm__input-container">
              <InputField
                type="number"
                placeholder={t(
                  "jobs.newShortTermJob.jobDetails.hourlyRate.placeholder",
                )}
                name="hourly_rate"
                value={formik.values.hourly_rate}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="ShortTermForm__input"
                textIcon="&#8364;/h"
              />
              {formik.errors.hourly_rate && formik.touched.hourly_rate ? (
                <p className="ShortTermForm__input-error">
                  {formik.errors.hourly_rate as string}
                </p>
              ) : (
                <div className="ShortTermForm__seat-info">
                  <img
                    src={info}
                    alt="info icon"
                    className="ShortTermForm__shift-info-icon"
                  />
                  <div className="ShortTermForm__shift-info-text">
                    {t("jobs.newShortTermJob.jobDetails.hourlyRate.min")}
                  </div>
                </div>
              )}
            </div>
            <div className="ShortTermForm__input-container">
              <InputField
                type="number"
                placeholder={t(
                  "jobs.newShortTermJob.jobDetails.seats.placeholder",
                )}
                name="seats"
                value={formik.values.seats}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="ShortTermForm__input"
                min="1"
                max="100"
              />
              {formik.errors.seats && formik.touched.seats && (
                <p className="ShortTermForm__input-error">
                  {formik.errors.seats as string}
                </p>
              )}
            </div>
            <div
              className="ShortTermForm__input-container"
              onMouseOut={() => {
              }}
            >
              <SelectBox
                items={businessUnits}
                selectedItem={selectedUnit}
                onItemSelect={(item: any) => {
                  setSelectedUnit(item);
                  console.log("item", item);
                  setSelectError({
                    name: "Unit",
                    active: false,
                  });
                }}
                placeholder={t(
                  "jobs.newShortTermJob.jobDetails.businessUnit.placeholder",
                )}
                onClick={() => {
                }}
                onBlur={(e: any) => {
                  if (selectedUnit?.name?.length === 0) {
                    setSelectError({name: "Unit", active: true});
                  }
                }}
                onChange={(e: any) => {
                }}
                onNewUnitClicked={() => {
                  setOpenNewUnitModal(true);
                }}
              />

              {selectError.active && selectError.name === "Unit" && (
                <h5 className="ShortTermForm__input-error">
                  {t("jobs.newShortTermJob.jobDetails.businessUnit.error")}
                </h5>
              )}
            </div>
            <div className="ShortTermForm__input-container">
              <TextAreaInput
                placeholder={t(
                  "jobs.newShortTermJob.jobDetails.description.placeholder",
                )}
                name="description"
                value={formik.values.description}
                onBlur={(e: any) => formik.handleBlur(e)}
                onChange={(e: any) => formik.handleChange(e)}
                className="ShortTermForm__textarea"
              />
              {formik.errors.description && formik.touched.description && (
                <p className="ShortTermForm__input-error">
                  {formik.errors.description as string}
                </p>
              )}
            </div>
            <div className="ShortTermForm__images-label-wrapper">
              <div className="ShortTermForm__images-label">
                {t("jobs.newShortTermJob.jobDetails.images.title")}
              </div>
              <img
                src={info}
                alt="info icon"
                className="ShortTermForm__info-icon"
              />
              <span className="ShortTermForm__images-note">
                {" "}
                {t("jobs.newShortTermJob.jobDetails.images.optional")}
              </span>
            </div>
            <PhotoProvider
              maskOpacity={0.7}
              overlayRender={(props) => {
                return (
                  <>
                    <SlideArrowLeft {...props} />
                    <SlideArrowRight {...props} />
                    <CloseButton onClick={props.onClose}/>
                  </>
                );
              }}
            >
              <div className="ShortTermForm__images">
                {base64Images?.length !== 0 &&
                  base64Images?.map((item: any, index: number) => (
                    <div
                      className="ShortTermForm__image-preview-container"
                      key={index}
                    >
                      <PhotoView src={item} key={index}>
                        <img
                          alt="image preview"
                          src={item}
                          className="ShortTermForm__image-preview"
                          style={{
                            width: "92px",
                            height: "92px",
                            objectFit: "cover",
                            borderRadius: "6px",
                          }}
                        />
                      </PhotoView>
                      <img
                        alt="delete icon"
                        src={deleteIcon}
                        className="ShortTermForm__delete-icon"
                        onClick={() => {
                          const filterItems: any = base64Images?.filter(
                            (image) => image !== item,
                          );
                          setBase64Images(filterItems);
                        }}
                      />
                    </div>
                  ))}
                {base64Images.length < 10 && (
                  <div
                    onClick={() => setIsImageUploaderOpen(true)}
                    className="ShortTermForm__images-placeholder"
                  >
                    <img
                      src={closeGray}
                      alt="plus icon"
                      className="ShortTermForm__add-icon"
                    />
                  </div>
                )}
              </div>
            </PhotoProvider>
            {isImageUploaderOpen && (
              <MultiFileUploader
                accept="image/*"
                onCancel={() => {
                  setIsImageUploaderOpen(false);
                }}
                onSubmit={(images: any) => {
                  setBase64Images((prev: any) => [...prev, ...images]);
                  setIsImageUploaderOpen(false);
                }}
                allImages={base64Images}
                maxImageSize={10485760}
              />
            )}
          </div>
          <div className="ShortTermForm__column">
            <div className="ShortTermForm__shift-label">
              {" "}
              {t("jobs.newShortTermJob.shifts.title")}
            </div>
            <ShortTermPicker
              initialShifts={shifts}
              sendBackDates={(dates: any) => {
                setShifts(dates);
              }}
            />
          </div>
        </div>

        <Button
          label={t("jobs.newShortTermJob.completeButton")}
          onClick={() => {
            setOpenConfirmModal(true);
          }}
          className="ShortTermForm__button"
          type="submit"
          disabled={
            !!formik.errors.job_title_text ||
            shifts?.length === 0 ||
            !!formik.errors.description ||
            !!formik.errors.hourly_rate ||
            !!formik.errors.seats ||
            !!selectError.active ||
            isButtonLoading ||
            !formik.values.description ||
            !formik.values.hourly_rate ||
            !formik.values.seats ||
            selectedUnit?.name.length === 0
          }
          isLoading={isButtonLoading}
        />
        {openConfirmModal && (
          <ConfirmJobPaymentModal
            buttonLabel={t("jobs.newLongTermJob.completeButton")}
            title={
              selectedLanguage === "SQ"
                ? "Jeni i sigurt që dëshironi ta postoni këtë punë?"
                : "Are you sure you want to post this job?"
            }
            subTitle={
              selectedLanguage === "SQ"
                ? "Deri në pagesën e punës, puna nuk do të jetë e disponueshme për aplikantët."
                : "Until the job payment, the job will not be available for applicants."
            }
            onCancel={() =>
              setOpenConfirmModal(false)
            }
            onSubmit={() => {
              formik.handleSubmit();
              setOpenConfirmModal(false)
            }}
            onSelectedPackageChange={(selectedPackage) => {
              setIsProJob(selectedPackage === "pro");
            }}
            onSelectedPaymentMethodChange={(selectedPaymentMethod) => {
              setPayWithBankTransfer(selectedPaymentMethod === "payBankTransfer");
            }}
          />
        )}
        {openNewUnitModal && (
          <NewUnitModal
            onClose={() => {
              setOpenNewUnitModal(false);
            }}
            onUnitCreate={(unit) => {
              setSelectedUnit(unit);
              console.log("unit", unit);
              setSelectError({
                name: "Unit",
                active: false,
              });
              refreshUnits();
              setOpenNewUnitModal(false);
            }}
          />
        )}

        {showBankTransferModal && (
          <WarningModal
            title={t("jobHasBeenPostedYouWillReceiveInvoiceToActivate")}
            buttonLabel={t("continue")}
            onCancel={() => {
              setShowBankTransferModal(false);
              navigate("/jobs/short-term-not-assigned");
              globalCtx.openMessageModal(
                "warning",
                t("jobHasBeenPostedYouWillReceiveInvoiceToActivate"),
              );
            }}
            onSubmit={() => {
              setShowBankTransferModal(false);
              navigate("/jobs/short-term-not-assigned");
              globalCtx.openMessageModal(
                "warning",
                t("jobHasBeenPostedYouWillReceiveInvoiceToActivate"),
              );
            }}
            hideCancelBtn={true}
          />
        )}
      </div>
    </>
  );
};

export default ShortTermForm;
