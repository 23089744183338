import React, {useEffect, useRef, useState} from "react";
import {apiCall} from "../../api/Api";
import LoadingComponent from "../../components/shared/LoadingComponent/LoadingComponent";
import cs from "classnames";
import {useNavigate} from "react-router-dom";
import reloadIcon from "../../assets/svg/Refresh_icon.svg";
import circle from "../../assets/svg/sign-up-rounds.svg";

import {useTranslation} from "react-i18next";

import "./Lists.scss";
import {useOutsideClickDetection} from "../../lib/hooks/useOutSideClickDetection";
import {useGlobalContext} from "../../context/GlobalContext/GlobalContext";
import {useAuthContext} from "../../context/AuthContext/AuthContext";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {IconButton} from "@mui/material";
import CreateListModal from "../../components/shared/CreateListModal/CreateListModal";
import {convertRestApiErrorCodes} from "../../lib/helpers/handleErrors";
import WarningModal from "../../components/shared/WarningModal/WarningModal";
import {Edit} from "@mui/icons-material";
import Enterprise from "../Plans/Enterprise/Enterprise";
import BusinessPlus from "../Plans/BusinessPlus/BusinessPlus";

const Lists = () => {
    let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
    let selectedLanguage = localStorage.getItem("lang");
    const globalCtx = useGlobalContext();
    const authCtx = useAuthContext();
    const [lists, setLists] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshCalled, setRefreshCalled] = useState(false);
    const rightSidebarRef = useRef(null);
    const {t} = useTranslation("common");
    const navigate = useNavigate();

    const [selectedFilter, setSelectedFilter] = useState<string>('');
    const [showCreateListModal, setShowCreateListModal] = useState<boolean>(false);
    const [showDeleteListModal, setShowDeleteListModal] = useState<boolean>(false);
    const [selectedListToDelete, setSelectedListToDelete] = useState<any>(null);
    const [showEditListModal, setShowEditListModal] = useState<boolean>(false);
    const [selectedListToEdit, setSelectedListToEdit] = useState<any>(null);


    useEffect(() => {
      setLoading(true);
      refresh();
    }, [selectedFilter]);

    useEffect(() => {
      console.log("selectedListToDelete", selectedListToDelete);
      if (selectedListToDelete) {
        setShowDeleteListModal(true);
      } else {
        setShowDeleteListModal(false);
      }
    }, [selectedListToDelete]);

    useEffect(() => {
      console.log("selectedListToEdit", selectedListToEdit);
      if (selectedListToEdit) {
        setShowEditListModal(true);
      } else {
        setShowEditListModal(false);
      }
    }, [selectedListToEdit]);

    const editList = (data: any) => {
      try {
        apiCall("PUT", `search/folders/${selectedListToEdit?.id}/`, data)
          .then((res) => {
            setIsLoading(false);
            getLists();

            globalCtx.openMessageModal(
              "success",
              t("listEdited"),
            );
          })
          .catch((err) => {
              setIsLoading(false);
              const errorMessage = convertRestApiErrorCodes(
                err?.response?.data?.code,
                t,
              );
              globalCtx.openMessageModal("error", errorMessage);
            }
          );
      } catch (err) {
        setIsLoading(false);
      }
    }

    const createList = (data: any) => {
      try {
        apiCall("POST", `search/folders/`, data)
          .then((res) => {
            setIsLoading(false);
            setLists([...lists, res.data]);

            globalCtx.openMessageModal(
              "success",
              t("listCreated"),
            );
          })
          .catch((err) => {
              setIsLoading(false);
              const errorMessage = convertRestApiErrorCodes(
                err?.response?.data?.code,
                t,
              );
              globalCtx.openMessageModal("error", errorMessage);
            }
          );
      } catch (err) {
        setIsLoading(false);
      }
    }

    const deleteList = () => {
      if (isLoading) return;
      try {
        console.log("delete list", selectedListToDelete);
        apiCall("DELETE", `search/folders/${selectedListToDelete}/`)
          .then((res) => {
            setIsLoading(false);
            setLists(lists.filter((list: any) => list.id !== selectedListToDelete));
            setSelectedListToDelete(null);

            globalCtx.openMessageModal(
              "success",
              t("listDeleted"),
            );
          })
          .catch((err) => {
              console.log(err);
              setIsLoading(false);
              const errorMessage = convertRestApiErrorCodes(
                err?.response?.data?.code,
                t,
              );
              globalCtx.openMessageModal("error", errorMessage);
            }
          );
      } catch (err) {
        setIsLoading(false);
      }
    }


    const getLists = () => {
      if (authCtx.localOrganization.is_enterprise) {
        try {
          setIsLoading(true);
          apiCall("get", "search/folders/").then((res) => {
            setLists(res.data);
          }).catch((err) => {
              setIsLoading(false);
              const errorMessage = convertRestApiErrorCodes(
                err?.response?.data?.code,
                t,
              );
              globalCtx.openMessageModal("error", errorMessage);
            }
          );
          setIsLoading(false);
        } catch (err) {
          setIsLoading(false);
        }
      }
    }

    useEffect(() => {
      getLists();
    }, []);


    const refresh = () => {
      getLists();
    };

    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    return (
      <section className="Lists">
        <div className="Lists__header">
          <div className="Lists__title-container">
            <h1 className="Lists__default-title">
              {t("Lists")}
            </h1>
            {isLoading ? (
              <div className="Lists__spinner"/>
              // <LoadingComponent/>
            ) : (
              <img
                src={reloadIcon}
                alt="reload icon"
                className={cs(
                  "Lists__reload-icon",
                  refreshCalled && "Lists__reload-icon--disable",
                )}
                onClick={() => {
                  if (!refreshCalled) {
                    refresh();
                    setRefreshCalled(true);
                    setTimeout(() => {
                      setRefreshCalled(false);
                    }, 10000);
                  }
                }}
              />
            )}
            <IconButton
              size={"medium"}
              color="primary"
              aria-label={t("createList")}
              component="span"
              onClick={() => setShowCreateListModal(true)}
            >
              +
            </IconButton>
          </div>
          <div className="Lists__header-chips">

          </div>
        </div>
        {!isLoading && lists?.length === 0 && (
          !authCtx.localOrganization.is_enterprise ? (
            <div className="Plans__products__container">
              <p className="JobAnalytics__subscribe-subtitle" onClick={() => navigate("/plans")}>
                {t("subscribeToUseLists")}
              </p>
            </div>
          ) : (
            <div className="Lists__no-content">
              {t("youDontHaveAnyListCreated")}
            </div>
          )
        )}

        {isLoading && loading && lists?.length === 0 && (
          <div className="Lists__no-content">
            <LoadingComponent/>
          </div>
        )}
        <div className="Lists__cards-wrapper">
          {lists &&
            lists.map((list: any, index: number) => (
              <div key={index} className="Applications__card">
                <Card sx={{maxWidth: 345}}>
                  <CardMedia
                    component="img"
                    height="140"
                    src={circle}
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {list.name}
                      <IconButton
                        size={"small"}
                        color="primary"
                        aria-label={t("editList")}
                        component="span"
                        onClick={() => setSelectedListToEdit(list)}
                      >
                        <Edit/>
                      </IconButton>
                    </Typography>
                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                      {list.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedListToDelete(list.id);
                      }}
                    >
                      {t("deleteList")}
                    </Button>
                    <Button
                      size="small"
                      color={"primary"}
                      onClick={() => {
                        navigate(`/lists/users/${list.id}`);
                      }}
                    >
                      {t("usersInList")} ({list.users.length})
                    </Button>
                  </CardActions>
                </Card>
              </div>
            ))}
        </div>
        {/*<div>*/}
        {/*  {nextPage && nextPage !== null && (*/}
        {/*    <div ref={sentryRef}>*/}
        {/*      <LoadingComponent/>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
        {isLoading && !loading && lists?.length === 0 && (
          <div className="Lists__no-content">
            <LoadingComponent/>
          </div>
        )}

        {showCreateListModal && (
          <CreateListModal
            onCancel={() => setShowCreateListModal(false)}
            onSubmit={(values) => {
              console.log(values);
              setShowCreateListModal(false);
              createList(values);
            }}
            buttonLabel={t("createList")}
            hideCancelBtn={true}
            title={t("fillTheFormToCreateList")}
          />
        )}

        {showDeleteListModal && (
          <WarningModal
            onCancel={() => setSelectedListToDelete(null)}
            onSubmit={() => {
              deleteList();
              setShowDeleteListModal(false);
            }}
            buttonLabel={t("deleteList")}
            hideCancelBtn={true}
            title={t("areYouSureYouWantToDeleteList")}
          />
        )}

        {showEditListModal && (
          <CreateListModal
            onCancel={() => setShowEditListModal(false)}
            onSubmit={(values) => {
              setShowEditListModal(false);
              editList(values);
            }}
            buttonLabel={t("edit")}
            title={t("editList")}
            hideCancelBtn={true}
            listName={selectedListToEdit?.name}
            listDescription={selectedListToEdit?.description}
          />
        )}


      </section>
    );
  }
;

export default Lists;
