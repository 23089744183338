import React, {useEffect, useRef, useState} from "react";
import {apiCall} from "../../../api/Api";
import LoadingComponent from "../../../components/shared/LoadingComponent/LoadingComponent";
import cs from "classnames";
import {useNavigate, useParams} from "react-router-dom";
import reloadIcon from "../../../assets/svg/Refresh_icon.svg";
import circle from "../../../assets/svg/sign-up-rounds.svg";

import {useTranslation} from "react-i18next";

import "./ListUsers.scss";
import {useOutsideClickDetection} from "../../../lib/hooks/useOutSideClickDetection";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import SearchCard from "../../Search/SearchUserCard/SearchCard";
import RightSidebar from "../../../components/shared/RightSidebar/RightSidebar";
import SearchResultSidebar from "../../Search/SearchUserSidebar/SearchResultSidebar";
import chevronLeft from "../../../assets/svg/chevron-down-gray.svg";

const ListUsers = () => {
    const { listId } = useParams();
    let selectedLanguage = localStorage.getItem("lang");
    const globalCtx = useGlobalContext();
    const authCtx = useAuthContext();
    const [list, setList] = useState<any>();
    const [ListUsers, setListUsers] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [error] = useState("");
    const [nextPage, setNextPage] = useState<any>("");
    const [isCalled, setIsCalled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openRightSidebar, setOpenSidebar] = useState(false);
    const [selectedApplicant, setSelectedApplicant] = useState<any>({});
    const [refreshCalled, setRefreshCalled] = useState(false);
    const rightSidebarRef = useRef(null);
    const {t} = useTranslation("common");
    const navigate = useNavigate();

    const [selectedFilter, setSelectedFilter] = useState<string>('');


    useEffect(() => {
      setLoading(true);
      refresh();
    }, [selectedFilter]);

    const hideRightSidebar = () => {
      setOpenSidebar(false);
      setSelectedApplicant({});
    };


    const getListUsers = () => {
      try {
        setIsLoading(true);
        apiCall("get", `search/folders/${listId}/users/`).then((res) => {
          setListUsers(res.data);
        });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }

    const getList = () => {
      try {
        setIsLoading(true);
        apiCall("get", `search/folders/${listId}/`).then((res) => {
          setList(res.data);
        });
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    }

    useEffect(() => {
      getListUsers();
      getList();
    }, []);


    const refresh = () => {
      getList();
      getListUsers();
    };

    const getRandomColor = () => {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    };

    useOutsideClickDetection(rightSidebarRef, () => {
      hideRightSidebar();
    });

    return (
      <section className="ListUsers">
        <div className="ListUsers__header">
          <div className="ListUsers__title-container">
            <img
              alt="chevron left icon"
              src={chevronLeft}
              className="ListUsers__back-icon"
              onClick={() => navigate(-1)}
            />
            <h1 className="ListUsers__default-title">
              {t("list")}: {list?.name} ({list?.users.length})
            </h1>
            {isLoading ? (
              <div className="ListUsers__spinner"/>
              // <LoadingComponent/>
            ) : (
              <img
                src={reloadIcon}
                alt="reload icon"
                className={cs(
                  "ListUsers__reload-icon",
                  refreshCalled && "ListUsers__reload-icon--disable",
                )}
                onClick={() => {
                  if (!refreshCalled) {
                    refresh();
                    setRefreshCalled(true);
                    setTimeout(() => {
                      setRefreshCalled(false);
                    }, 10000);
                  }
                }}
              />
            )}
          </div>
          <div className="ListUsers__header-chips">

          </div>
        </div>
        {!isLoading && ListUsers?.length === 0 && (
          <div className="ListUsers__no-content">
            {t("noUsersInList")}
          </div>
        )}
        {isLoading && loading && ListUsers?.length === 0 && (
          <div className="ListUsers__no-content">
            <LoadingComponent/>
          </div>
        )}
        <div className="ListUsers__cards-wrapper">
          {ListUsers &&
            ListUsers.map((person: any, index: number) => (
              <div key={index} className="Applications__card">
                <SearchCard
                  person={person}
                  setSelectedApplicant={setSelectedApplicant}
                  setOpenSidebar={setOpenSidebar}
                  refreshList={refresh}
                  cardIsInList={true}
                  listId={listId}
                />
              </div>
            ))}
        </div>

        {openRightSidebar && (
          <div>
            <RightSidebar
              onClose={() => hideRightSidebar()}
              sidebarRef={rightSidebarRef}
            >
              <SearchResultSidebar
                person={selectedApplicant}
                onClose={() => hideRightSidebar()}
                loadMore={refresh}
              />
            </RightSidebar>
          </div>
        )}
        {/*<div>*/}
        {/*  {nextPage && nextPage !== null && (*/}
        {/*    <div ref={sentryRef}>*/}
        {/*      <LoadingComponent/>*/}
        {/*    </div>*/}
        {/*  )}*/}
        {/*</div>*/}
        {isLoading && !loading && ListUsers?.length === 0 && (
          <div className="ListUsers__no-content">
            <LoadingComponent/>
          </div>
        )}


      </section>
    );
  }
;

export default ListUsers;
