import React, { useCallback, useEffect, useRef, useState } from "react";

import cs from "classnames";
import TimePicker from "../TimePicker/TimePicker";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutSideClickDetection";
import MonthPicker from "../MonthPicker/MonthPicker";
import { calculateTimeDifference } from "../../../lib/helpers/calculateTimeDifference";
import { useTranslation } from "react-i18next";
import info from "../../../assets/svg/info.svg";

import "./LongTermPicker.scss";
import { useLocation } from "react-router-dom";
import {Backspace} from "@mui/icons-material";
import {LongTermPickerDataType} from "../../../pages/Jobs/LongTermForm/LongTermForm";
import {isEqual} from "lodash";

interface LongTermPickerProps {
  sendBackData: (data: LongTermPickerDataType | null) => void;
  initialData?: LongTermPickerDataType | null;
  showDurationRequiredError?: boolean;
}

interface dayType {
  index: number;
  dayEn: string;
  daySq: string;
}

const LongTermPicker = (props: LongTermPickerProps) => {
  const { sendBackData } = props;
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedDays, setSelectedDays] = useState<dayType[]>([]);
  const [selectedStartTime, setSelectedStartTime] = useState("");
  const [selectedEndTime, setSelectedEndTime] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const calendarRef = useRef(null);
  const prevInitialData = useRef(props.initialData);
  const { t } = useTranslation("common");
  const langFromLocalStorage = localStorage.getItem("lang");
  let jobStorage = JSON.parse(localStorage.getItem("selectedJob")!!);
  const location = useLocation();
  const prevData = useRef<LongTermPickerDataType | null>(null);

  useEffect(() => {
    if (props.initialData) {
      const { job_start_time, schedule } = props.initialData;

      setSelectedStartDate(job_start_time ? new Date(job_start_time) : null);
      setSelectedStartTime(schedule?.start_time || "");
      setSelectedEndTime(schedule?.end_time || "");
      setSelectedDays(
        schedule?.shift_days
          ? days.filter((day) => schedule.shift_days.includes(day.index))
          : []
      );
      setSelectedMonth(schedule?.length_in_months || null);

    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/jobs/edit-long-term-job") {
      if (jobStorage.job_start_time) {
        const startDateObject = new Date(jobStorage.job_start_time);
        setSelectedStartDate(startDateObject);
      }

      if (jobStorage?.schedule?.shift_days.length > 0) {
        const daysMatched = days.filter((day) =>
          jobStorage?.schedule?.shift_days.includes(day.index),
        );
        setSelectedDays(daysMatched);
      }
      if (jobStorage?.schedule?.start_time) {
        setSelectedStartTime(jobStorage?.schedule?.start_time);
      }
      if (jobStorage?.schedule?.end_time) {
        setSelectedEndTime(jobStorage?.schedule?.end_time);
      }
    }
  }, []);

  // useEffect(() => {
  //   setSelectedMonth(0);
  // }, []);

  const days = [
    { index: 0, dayEn: "Mon", daySq: "Hën" },
    { index: 1, dayEn: "Tue", daySq: "Mar" },
    { index: 2, dayEn: "Wed", daySq: "Mër" },
    { index: 3, dayEn: "Thu", daySq: "Enj" },
    { index: 4, dayEn: "Fri", daySq: "Pre" },
    { index: 5, dayEn: "Sat", daySq: "Sht" },
    { index: 6, dayEn: "Sun", daySq: "Die" },
  ];

  const handleDayClick = (day: dayType, index: number) => {
    const dayExists = selectedDays.some(
      (selectedDay) => selectedDay.index === day.index,
    );

    if (dayExists) {
      // Remove the day if it's already selected
      setSelectedDays(
        selectedDays.filter((selectedDay) => selectedDay.index !== day.index),
      );
    } else {
      // Add the day if it's not selected
      setSelectedDays([...selectedDays, day]);
    }
  };

  const tileClassName = useCallback(
    ({ date }: { date: Date }) => {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set time to midnight to ensure comparison is just for the day

      if (date < today) {
        return "past-date"; // Add class for past dates
      }

      if (selectedStartDate?.toDateString() === date.toDateString()) {
        return "selected"; // Add a class for selected dates
      } else {
        return "unselected"; // Add a class for unselected dates
      }
    },
    [selectedStartDate],
  );

  useOutsideClickDetection(calendarRef, () => {
    setIsCalendarOpen(false);
  });

  useEffect(() => {
    setErrorMessage("");
    console.log("length in months", selectedMonth);

    const isPartialSelection =
      (selectedStartTime && (!selectedEndTime || selectedDays.length === 0)) ||
      (selectedEndTime && (!selectedStartTime || selectedDays.length === 0)) ||
      (selectedDays.length > 0 && (!selectedStartTime || !selectedEndTime));

    if (isPartialSelection) {
      if (selectedDays.length > 0 && !selectedStartTime) {
        setErrorMessage(t("longTermPicker.errorThree"));
      } else if (selectedDays.length > 0 && !selectedEndTime) {
        setErrorMessage(t("longTermPicker.errorFour"));
      } else if (!selectedStartTime && selectedEndTime) {
        setErrorMessage(t("longTermPicker.errorFive"));
      } else if (selectedStartTime && !selectedEndTime) {
        setErrorMessage(t("longTermPicker.errorSix"));
      }
      sendBackData(null);
      return;
    }

    const timeDifference = calculateTimeDifference(
      selectedStartTime,
      selectedEndTime
    );
    const [timeDifferenceHours, timeDifferenceMinutes] = timeDifference
      .split(":")
      .map(Number);

    if (
      timeDifferenceHours > 12 ||
      (timeDifferenceHours === 12 && timeDifferenceMinutes > 0)
    ) {
      setErrorMessage(t("longTermPicker.errorOne"));
      sendBackData(null);
      return; // Do not call sendBackData when time difference is invalid
    }
    if (timeDifferenceHours < 3) {
      setErrorMessage(t("longTermPicker.errorTwo"));
      sendBackData(null);
      return; // Do not call sendBackData when time difference is invalid
    }
    console.log("length in months", selectedMonth);

    const newData = {
      job_start_time: selectedStartDate?.toISOString() || null,
      schedule: {
        start_time: selectedStartTime,
        end_time: selectedEndTime,
        length_in_months: selectedMonth,
        shift_days: selectedDays.map((day) => day.index),
      },
    };

    // Only send data back if it has changed
    if (!isEqual(prevData.current, newData)) {
      sendBackData(newData);
      prevData.current = newData;
    }
  }, [
    selectedStartDate,
    selectedStartTime,
    selectedEndTime,
    selectedMonth,
    selectedDays,
    sendBackData, // Add sendBackData to dependencies
  ]);



  return (
    <div className="LongTermPicker">
      <div className="LongTermPicker__dates">
        <div className="LongTermPicker__calendar-container" ref={calendarRef}>
          <div
            className={cs(
              "LongTermPicker__calendar-input",
              selectedStartDate === null &&
                "LongTermPicker__calendar-input-placeholder",
            )}
            onClick={() => {
              setIsCalendarOpen(true);
            }}
          >
            {selectedStartDate
              ? selectedStartDate.toLocaleDateString()
              : t("longTermPicker.startDate")}
            {selectedStartDate && (
              <Backspace sx={{fontSize: 20}} onClick={() => {
                setSelectedStartDate(null);
              } } />
            )}
          </div>

          <div className="LongTermPicker__hint">
            <img
              src={info}
              alt="info icon"
              className="LongTermPicker__hint-icon"
            />
            <div className="LongTermPicker__hint-label">
              {t("jobs.newLongTermJob.jobDetails.images.optional")}
            </div>
          </div>
          {isCalendarOpen && (
            <div className="LongTermPicker__calendar">
              <Calendar
                minDate={new Date()}
                onClickDay={(date) => {
                  setSelectedStartDate(date);
                  setIsCalendarOpen(false);
                }}
                tileClassName={tileClassName}
              />
            </div>
          )}
        </div>
        <div className="LongTermPicker__duration">
          <MonthPicker
            label={t("longTermPicker.duration")}
            sendBackMonth={(month) => {
              setSelectedMonth(month);
            }}
            showDurationRequiredError={props.showDurationRequiredError}
            selectedMonthFromParent={selectedMonth}
          />
        </div>
      </div>
      <div className="LongTermPicker__days">
        <div className="LongTermForm__jobType-title-container">
          <div className="LongTermForm__jobType-title">
            {t("jobs.newLongTermJob.jobSchedule.timePlanification")}
          </div>
          <img src={info} alt="info icon" className="LongTermForm__info-icon" />
          <span className="LongTermForm__images-note">
            {t("jobs.newLongTermJob.jobDetails.images.optional")}
          </span>
        </div>

        <div className="LongTermPicker__days-container">
          {days.map((day, index) => (
            <div
              onClick={() => {
                handleDayClick(day, index);
              }}
              key={index}
              className={cs(
                "LongTermPicker__day",
                selectedDays.some(
                  (selectedDay) => selectedDay.index === day.index,
                ) && "LongTermPicker__day-active",
                day.dayEn === "Sat" && "LongTermPicker__day-weekend",
                day.dayEn === "Sun" && "LongTermPicker__day-weekend",
                selectedDays.some(
                  (selectedDay) => selectedDay.index === day.index,
                ) &&
                  day.dayEn === "Sat" &&
                  "LongTermPicker__day-weekend-active",
                selectedDays.some(
                  (selectedDay) => selectedDay.index === day.index,
                ) &&
                  day.dayEn === "Sun" &&
                  "LongTermPicker__day-weekend-active",
              )}
            >
              {langFromLocalStorage === "EN" ? day.dayEn : day.daySq}
            </div>
          ))}
        </div>
      </div>
      <div className="LongTermPicker__times">
        <TimePicker
          timeFromOut={selectedStartTime}
          label={t("longTermPicker.startTime")}
          sendBackTime={(time) => {
            setSelectedStartTime(time);
          }}
        />
        <TimePicker
          timeFromOut={selectedEndTime}
          label={t("longTermPicker.endTime")}
          sendBackTime={(time) => {
            setSelectedEndTime(time);
          }}
        />
      </div>
      {errorMessage !== "" && (
        <h5 className="LongTermPicker__errorMessage">{errorMessage}</h5>
      )}
    </div>
  );
};

export default LongTermPicker;
