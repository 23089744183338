import React from "react";

import {useAuthContext} from "../../context/AuthContext/AuthContext";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useResponsiveDimensions} from "../../lib/hooks/useResponsiveDimensions";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

import "./Plans.scss";
import Basic from "./Basic/Basic";
import Pro from "./Pro/Pro";
import BusinessPlus from "./BusinessPlus/BusinessPlus";
import Enterprise from "./Enterprise/Enterprise";
import BusinessPlusAnnual from "./BusinessPlus/Annual/BusinessPlusAnnual";
import EnterpriseAnnual from "./Enterprise/Annual/EnterpriseAnnual";
import {Button, Stack, styled} from "@mui/material";
import {ThemeProvider, createTheme} from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../firebaseConfig";


const AntSwitch = styled(Switch)(({theme}) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));

const Plans = () => {
  const authCtx = useAuthContext();
  const navigate = useNavigate();
  const {t} = useTranslation("common");
  const isMobile = useResponsiveDimensions().isMobile;
  const [showPerPostPlans, setShowPerPostPlans] = React.useState(false);
  const [showMonthlyPlans, setShowMonthlyPlans] = React.useState(false);
  const [showYearlyPlans, setShowYearlyPlans] = React.useState(false);
  const theme = createTheme({
    palette: {
      primary: {
        main: '#008C61',
      },
      secondary: {
        main: '#005BE2',
      }
    },
  });
  return (
    <div className="Plans">
      <p className="Plans__title">
        <span>Peakjobs Oferta</span> {""}
        {isMobile && <br/>}
        <span>{authCtx.localUserProfile.display_name}</span>{" "}
      </p>
      <p className="Plans__subtitle">
        Zgjidhni planin ideal për nevojat e biznesit tuaj.
      </p>

      <FormGroup
        className="Plans__switches"
        row
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "center",
        }}
      >
        <ThemeProvider theme={theme}>

          <Button
            variant={showPerPostPlans ? "contained" : "outlined"}
            onClick={() => {
              setShowPerPostPlans((prev) => !prev);
              setShowMonthlyPlans(false);
              setShowYearlyPlans(false);
              logEvent(analytics, "click_per_post_plans", {
                organization: authCtx.localOrganization.name || "unknown",
              });
            }}
            size={isMobile ? "medium" : "large"}
            color="secondary"
          >
            {t("forJobPost")}
          </Button>
        </ThemeProvider>
        <ThemeProvider theme={theme}>
          <Button
            variant={(showMonthlyPlans || showYearlyPlans) ? "contained" : "outlined"}
            onClick={() => {
              setShowMonthlyPlans((prev) => !prev);
              setShowPerPostPlans(false);
              setShowYearlyPlans(false);
              logEvent(analytics, "click_monthly_plans", {
                organization: authCtx.localOrganization.name || "unknown",
              });
            }}
            color="primary"
            size={isMobile ? "medium" : "large"}
          >
            {t("subscription")}
          </Button>
        </ThemeProvider>
      </FormGroup>

      {(showMonthlyPlans || showYearlyPlans) && (
        <Stack
          direction="row"
          spacing={1}
          sx={{alignItems: 'center'}}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography>Mujore</Typography>
          <AntSwitch
            inputProps={{ 'aria-label': 'ant design' }}
            checked={showYearlyPlans}
            onChange={() => {
              setShowYearlyPlans((prev) => !prev);
              setShowMonthlyPlans((prev) => !prev);
              logEvent(analytics, "click_yearly_plans", {
                organization: authCtx.localOrganization.name || "unknown",
              });
            }}
          />
          <Typography>Vjetore</Typography>
        </Stack>
      )}

      {showPerPostPlans && (
        <div className="Plans__products__container">
          <Basic/>
          <Pro/>
        </div>
      )}
      {showMonthlyPlans && (
        <div className="Plans__products__container">
          <BusinessPlus/>
          <Enterprise/>
        </div>
      )}
      {showYearlyPlans && (
        <div className="Plans__products__container">
          <BusinessPlusAnnual/>
          <EnterpriseAnnual/>
        </div>
      )}
      {(showPerPostPlans || showMonthlyPlans || showYearlyPlans) && (
        <p className="Plans__footer-text">
          *Të gjitha çmimet janë me TVSH.
        </p>
      )}
    </div>
  );
};

export default Plans;
