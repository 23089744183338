import React, {useState} from "react";

import {useTranslation} from "react-i18next";
import {useCreateListFormik} from "./useCreateListFormik";
import {useNavigate} from "react-router-dom";
import {useGlobalContext} from "../../../../context/GlobalContext/GlobalContext";
import {useAuthContext} from "../../../../context/AuthContext/AuthContext";
import {convertRestApiErrorCodes} from "../../../../lib/helpers/handleErrors";
import InputField from "../../InputField/InputField";

import "./CreateListForm.scss";
import {Checkbox, MenuItem, Select} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Button from "../../Button/Button";
import cs from "classnames";
import dayjs from "dayjs";

interface CreateListFormProps {
  onCancel: () => void;
  onSubmit: (values: any) => void;
  title?: string;
  description?: string;
  buttonLabel: string;
  titlePlaceholder: string;
}

const CreateListForm = (props: CreateListFormProps) => {
  const {t} = useTranslation("common");
  const globalCtx = useGlobalContext();

  const formik = useCreateListFormik({
    initialValues: {
      listName: props.title || "",
      listDescription: props.description || "",
    },
    onSubmit: async (values: any) => {
      console.log("Form submitted:", values);

      const data: any = {
        name: values.listName,
      };

      if (values.listDescription) {
        data.description = values.listDescription;
      }

      props.onSubmit(data);
    },
  });

  return (
    <>
      <div className="CreateListForm">
        <p className="CreateListForm__description">
          {props.titlePlaceholder}
        </p>
        <div className="CreateListForm__body">
          <div className="CreateListDetails">
            <div className="CreateListDetails__body">
              <div className="CreateListDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "listName",
                  )}
                  name="listName"
                  value={formik.values.listName}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="CreateListDetails__input-description">
                  {t(
                    "addListName",
                  )}
                </div>
                {formik.errors.listName &&
                  formik.touched.listName && (
                    <h5 className="CreateListDetails__input-error">
                      {formik.errors.listName as string}
                    </h5>
                  )}
              </div>
              {" "}
              <div className="CreateListDetails__input">
                <InputField
                  type="text"
                  placeholder={t(
                    "listDescription",
                  )}
                  name="listDescription"
                  value={formik.values.listDescription}
                  onBlur={(e: any) => formik.handleBlur(e)}
                  onChange={(e: any) => formik.handleChange(e)}
                />
                <div className="CreateListDetails__input-description">
                  {t(
                    "addListDescription",
                  )}
                </div>
                {formik.errors.listDescription &&
                  formik.touched.listDescription && (
                    <h5 className="CreateListDetails__input-error">
                      {formik.errors.listDescription as string}
                    </h5>
                  )}
              </div>
            </div>
            <div className="AddEducationModal__buttons">
              <Button
                type="submit"
                style={{height: "60px", marginLeft: "5px"}}
                label={props.buttonLabel}
                onClick={() => {
                  // props.onSubmit();
                  formik.handleSubmit();
                }}
                className="AddEducationModal__submit-btn"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateListForm;
