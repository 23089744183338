import React from "react";
import Button from "../Button/Button";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./CreateListModal.scss";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import AddEducationForm from "./CreateListForm/CreateListForm";

interface CreateListModalProps {
  onCancel: () => void;
  onSubmit: (values: any) => void;
  title: string;
  buttonLabel: string;
  hideCancelBtn?: boolean;
  listName?: string;
  listDescription?: string
}

const CreateListModal = (props: CreateListModalProps) => {
  const { t } = useTranslation("common");

  return (
    <div className="CreateListModal">
      <div className="CreateListModal__container">
        <img
          className="CreateListModal__close"
          alt="close icon"
          src={closeIcon}
          onClick={() => {
            props.onCancel();
          }}
        />
        <img src={ErrorIcon} alt="alert icon" className="CreateListModal__icon" />
        <AddEducationForm
          onCancel={props.onCancel}
          onSubmit={props.onSubmit}
          title={props.listName}
          description={props.listDescription}
          buttonLabel={props.buttonLabel}
          titlePlaceholder={props.title}
        />
      </div>
    </div>
  );
};

export default CreateListModal;
