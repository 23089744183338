import React, { useState } from "react";
import { GlobalContextType, GlobalContext } from "./GlobalContext";
import albaniaIcon from "../../assets/flags/albania.svg";
import unitedKingdomIcon from "../../assets/flags/united-kingdom.svg";
import { useTranslation } from "react-i18next";

interface GlobalContextProps {
  children: React.ReactNode | null;
}

export const GlobalContextProvider = (props: GlobalContextProps) => {
  const langFromLocalStorage = localStorage.getItem("lang");
  const [selectedLanguage, setSelectedLanguage] = useState({
    keyword: langFromLocalStorage === "SQ" ? "SQ" : "EN",
    label: langFromLocalStorage === "SQ" ? "SHQIP" : "ENGLISH",
  });
  const [messageModal, setMessageModal] = useState({
    type: "",
    text: "",
  });
  const { i18n } = useTranslation("common");

  const onLanguageSelect = (keyword: string, label: string) => {
    i18n.changeLanguage(keyword).then((res) => {
      setSelectedLanguage({
        keyword: keyword,
        label: label,
      });
      localStorage.setItem("lang", keyword);
    });
  };

  const openMessageModal = (type: string, text: string) => {
    setMessageModal({ type: type, text: text });

    setTimeout(() => {
      setMessageModal({ type: "", text: "" });
    }, 8000);
  };

  const closeMessageModal = () => {
    setMessageModal({ type: "", text: "" });
  };

  const context: GlobalContextType = {
    selectedLanguage,
    onLanguageSelect,
    messageModal,
    openMessageModal,
    closeMessageModal,
  };

  return (
    <GlobalContext.Provider value={context}>
      {props.children}
    </GlobalContext.Provider>
  );
};
