import React, {useEffect, useState} from "react";
import Button from "../Button/Button";
import ErrorIcon from "../../../assets/svg/error-emblem.svg";
import closeIcon from "../../../assets/svg/close-dark.svg";

import "./ConfirmJobPayment.scss";
import {useTranslation} from "react-i18next";
import cs from "classnames";
import {CheckCircleOutline, UnpublishedOutlined} from "@mui/icons-material";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {Radio} from "@mui/material";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";
import {logEvent} from "firebase/analytics";
import {analytics} from "../../../firebaseConfig";

interface ConfirmJobPaymentModalProps {
  onCancel: () => void;
  onSubmit: () => void;
  onSelectedPackageChange?: (selectedPackage: string) => void;
  onSelectedPaymentMethodChange?: (selectedPaymentMethod: string) => void;
  title?: string;
  subTitle?: string;
  buttonLabel: string;
  hideCancelBtn?: boolean;
  isLongTerm?: boolean;
}

const ConfirmJobPaymentModal = (props: ConfirmJobPaymentModalProps) => {
  const authCtx = useAuthContext();
  logEvent(analytics, "choose_plan_modal_opened", {
    organization: authCtx.localOrganization.name || "unknown",
  });
  const {t} = useTranslation("common");
  const [selectedPackage, setSelectedPackage] = useState("basic");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("payOnline");

  const buttonLabel = authCtx.isOrganizationBusinessPlusOrPro
    ? t("jobs.newShortTermJob.completeButton")
    : !authCtx.isOrganizationCreatedMonthAgo && selectedPackage === "basic"
      ? t("postForFree")
      : t("postAndPay")

  useEffect(() => {
    if (authCtx.isOrganizationBusinessPlusOrPro) {
      setSelectedPackage("pro");
    } else {
      setSelectedPackage("basic");
    }
  }, []);

  const handleSelectedPackageChange = (selectedPackage: string) => {
    setSelectedPackage(selectedPackage);
    if (props.onSelectedPackageChange) {
      props.onSelectedPackageChange(selectedPackage);
    }
  }

  const handleSelectedPaymentMethodChange = (selectedPaymentMethod: string) => {
    setSelectedPaymentMethod(selectedPaymentMethod);
    if (props.onSelectedPaymentMethodChange) {
      props.onSelectedPaymentMethodChange(selectedPaymentMethod);
    }
  }

  return (
    <div className="ConfirmJobPaymentModal">
      <div className="ConfirmJobPaymentModal__container">
        <img
          className="ConfirmJobPaymentModal__close"
          alt="close icon"
          src={closeIcon}
          onClick={() => {
            props.onCancel();
          }}
        />
        <img src={ErrorIcon} alt="alert icon" className="ConfirmJobPaymentModal__icon"/>

        <h2 className="ConfirmJobPaymentModal__label">
          {authCtx.isOrganizationBusinessPlusOrPro
            ? t("asSubscriber")
            : !authCtx.isOrganizationCreatedMonthAgo
              ? t("youCanPostForFreeFirstMonth")
              : t("choosePackage")}
        </h2>

        {authCtx.isOrganizationBusinessPlusOrPro ? (
          <div className="ConfirmJobPaymentModal__single__products__container">
            <div
              className={`SchedulePlanButton ${selectedPackage === "pro" ? "selected" : ""}`}
              onClick={() => handleSelectedPackageChange("pro")}
            >
              <div className="SchedulePlanButton__price_hint_pro">
                {t("pro")}
              </div>
              <div className="SchedulePlanButton__body">
                <div className="SchedulePlanButton__priceContainer">
                  <span className="SchedulePlanButton__price--new">  </span>
                </div>
                <div className="SchedulePlanButton__paragraph-included">
                  <CheckCircleOutline sx={{fontSize: 20}}
                                      style={{color: "green"}}/> {t("promotion")}
                </div>
                <div className="SchedulePlanButton__paragraph-included">
                  <CheckCircleOutline sx={{fontSize: 20}}
                                      style={{color: "green"}}/> {t("socialMediaPost")}
                </div>
                <div className="SchedulePlanButton__paragraph-included">
                  <CheckCircleOutline sx={{fontSize: 20}}
                                      style={{color: "green"}}/> {t("pushNotifications")}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="ConfirmJobPaymentModal__products__container">
            <div
              className={`SchedulePlanButton ${selectedPackage === "basic" ? "selected" : ""}`}
              onClick={() => handleSelectedPackageChange("basic")}
            >

              <div className="SchedulePlanButton__price_hint">
                {t("basic")}
              </div>
              <div className="SchedulePlanButton__body">

                {!authCtx.isOrganizationCreatedMonthAgo ? (
                  <div className="SchedulePlanButton__priceContainer">
                    <span className="SchedulePlanButton__price--new">00.00€</span>
                    <span className="SchedulePlanButton__price--old">9.99€</span>
                  </div>
                ) : (
                  <div className="SchedulePlanButton__priceContainer">
                    <span className="SchedulePlanButton__price--new">9.99€</span>
                  </div>
                )}
                <div className="SchedulePlanButton__paragraph-not-included">
                  <UnpublishedOutlined sx={{fontSize: 20}}
                                       style={{color: "gray"}}/> {t("promotion")}
                </div>
                <div className="SchedulePlanButton__paragraph-not-included">
                  <UnpublishedOutlined sx={{fontSize: 20}}
                                       style={{color: "gray"}}/> {t("socialMediaPost")}
                </div>
                <div className="SchedulePlanButton__paragraph-not-included">
                  <UnpublishedOutlined sx={{fontSize: 20}}
                                       style={{color: "gray"}}/> {t("pushNotifications")}
                </div>
              </div>
            </div>


            <div
              className={`SchedulePlanButton ${selectedPackage === "pro" ? "selected" : ""}`}
              onClick={() => handleSelectedPackageChange("pro")}
            >
            <div className="SchedulePlanButton__price_hint_pro">
                {t("pro")}
              </div>
              <div className="SchedulePlanButton__body">
                <div className="SchedulePlanButton__priceContainer">
                  <span className="SchedulePlanButton__price--new">59.99€</span>
                </div>
                <div className="SchedulePlanButton__paragraph-included">
                  <CheckCircleOutline sx={{fontSize: 20}}
                                      style={{color: "green"}}/> {t("promotion")}
                </div>
                <div className="SchedulePlanButton__paragraph-included">
                  <CheckCircleOutline sx={{fontSize: 20}}
                                      style={{color: "green"}}/> {t("socialMediaPost")}
                </div>
                <div className="SchedulePlanButton__paragraph-included">
                  <CheckCircleOutline sx={{fontSize: 20}}
                                      style={{color: "green"}}/> {t("pushNotifications")}
                </div>
              </div>
            </div>
          </div>
        )}
        {authCtx.isOrganizationBusinessPlusOrPro ? (
          <FormLabel id="demo-radio-buttons-group-label">{t("jobWillBeActiveImmediately")}</FormLabel>
        ) : (
          !authCtx.isOrganizationCreatedMonthAgo ? (
            selectedPackage === "pro" ? (
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">{t("paymentMethod")}</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={selectedPaymentMethod}
                  name="radio-buttons-group"
                >
                  <FormControlLabel onChange={() => handleSelectedPaymentMethodChange("payOnline")} value="payOnline"
                                    control={<Radio/>} label={t("payOnline")}/>
                  <FormControlLabel onChange={() => handleSelectedPaymentMethodChange("payBankTransfer")}
                                    value="bankTransfer"
                                    control={<Radio/>} label={t("payWithBankTransfer")}/>
                </RadioGroup>
              </FormControl>
            ) : (
              <FormLabel id="demo-radio-buttons-group-label">{t("jobWillBeActiveImmediately")}</FormLabel>
            )
          ) : (
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">{t("paymentMethod")}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={selectedPaymentMethod}
                name="radio-buttons-group"
              >
                <FormControlLabel onChange={() => handleSelectedPaymentMethodChange("payOnline")} value="payOnline"
                                  control={<Radio/>} label={t("payOnline")}/>
                <FormControlLabel onChange={() => handleSelectedPaymentMethodChange("payBankTransfer")}
                                  value="bankTransfer"
                                  control={<Radio/>} label={t("payWithBankTransfer")}/>
              </RadioGroup>
            </FormControl>
          )
        )}


        <div className="ConfirmJobPaymentModal__buttons">
          <Button
            type="submit"
            style={{height: "60px", marginLeft: "5px"}}
            label={buttonLabel}
            onClick={() => {
              props.onSubmit();
            }}
            className="ConfirmJobPaymentModal__submit-btn"
          />
        </div>
      </div>
    </div>

  )
    ;
};

export default ConfirmJobPaymentModal;
