import React, {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Avatar,
  Typography,
  Paper,
  Button,
  Box, IconButton, Popover, MenuItem, ListItemIcon, styled, tableCellClasses,
} from "@mui/material";

import {useTranslation} from "react-i18next";
import {
  AddCircle,
  Bookmark,
  BookmarkAddOutlined,
  ChatBubbleOutline, ChevronRight,
  MarkChatUnread,
  PlaylistAdd, Visibility, VisibilityOff
} from "@mui/icons-material";
import {apiCall} from "../../../../api/Api";
import Tooltip from "@mui/material/Tooltip";
import CommentModal from "../../../../components/shared/CommentModal/CommentModal";
import {convertRestApiErrorCodes} from "../../../../lib/helpers/handleErrors";
import {useGlobalContext} from "../../../../context/GlobalContext/GlobalContext";
import CreateListModal from "../../../../components/shared/CreateListModal/CreateListModal";

const ApplicationTable = ({applications, onViewClick}: {
  applications: any[];
  onViewClick: (applicant: any) => void;
}) => {
  const {t} = useTranslation("common");
  const globalCtx = useGlobalContext();
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState<any>({});
  const [commentSelectedApplicant, setCommentSelectedApplicant] = useState<any>(null);
  const [listAnchorEl, setListAnchorEl] = useState<null | HTMLElement>(null);
  const listOpen = Boolean(listAnchorEl);
  const [lists, setLists] = useState<any>([]);
  const [showCreateListModal, setShowCreateListModal] = useState<boolean>(false);
  const [localApplications, setLocalApplications] = useState(applications);

  useEffect(() => {
    setLocalApplications(applications);
  }, [applications]);

  const getLists = () => {
    try {
      apiCall("get", "search/folders/").then((res) => {
        setLists(res.data);
      }).catch((err) => {
          const errorMessage = convertRestApiErrorCodes(
            err?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
        }
      );
    } catch (err) {
    }
  }
  const createList = (data: any) => {
    try {
      apiCall("POST", `search/folders/`, data)
        .then((res) => {
          setLists([...lists, res.data]);

          globalCtx.openMessageModal(
            "success",
            t("listCreated"),
          );
        })
        .catch((err) => {
            const errorMessage = convertRestApiErrorCodes(
              err?.response?.data?.code,
              t,
            );
            globalCtx.openMessageModal("error", errorMessage);
          }
        );
    } catch (err) {
    }
  }

  const handleListClose = () => {
    setListAnchorEl(null);
    setSelectedApplicant({});
  }
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    getLists();
    setListAnchorEl(event.currentTarget);
  };

  const toggleFavorite = (applicationId: String) => {
    apiCall("POST", `b2b/applications/toggle-favourite/${applicationId}/`)
      .then((res) => {
        setLocalApplications((prevApplications) =>
          prevApplications.map((app) =>
            app.id === applicationId
              ? {...app, status: app.status === "favourite" ? "applied" : "favourite"}
              : app
          )
        );
      })
      .catch((err) => {
        const errorMessage = convertRestApiErrorCodes(err?.response?.data?.code, t);
        globalCtx.openMessageModal("error", errorMessage);
      });
  };

  const setApplicantComment = (comment: string) => {
    const updatedApplications = localApplications.map((application) => {
      if (application.id === commentSelectedApplicant.id) {
        return {
          ...application,
          comment: comment,
        };
      }
      return application;
    });
    setLocalApplications(updatedApplications);
    setOpenCommentModal(false);
    setCommentSelectedApplicant(null);
  };

  const toggleIsViewed = (applicationId: String) => {
    apiCall("POST", `b2b/applications/set-view/${applicationId}/`)
      .then((res) => {
        setLocalApplications((prevApplications) =>
          prevApplications.map((app) =>
            app.id === applicationId
              ? {...app, is_viewed: true}
              : app
          )
        );
      })
      .catch((err) => {
        const errorMessage = convertRestApiErrorCodes(err?.response?.data?.code, t);
        globalCtx.openMessageModal("error", errorMessage);
      });
  };

  const toggleIsDismissed = (applicationId: String) => {
    apiCall("POST", `b2b/applications/toggle-dismiss/${applicationId}/`)
      .then((res) => {
        // remove the dismissed application from the list
        setLocalApplications((prevApplications) =>
          prevApplications.filter((app) => app.id !== applicationId)
        );
      })
      .catch((err) => {
      });
  }

  useEffect(() => {
    if (commentSelectedApplicant !== null && commentSelectedApplicant !== undefined) {
      setOpenCommentModal(true);
    }
  }, [commentSelectedApplicant]);

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#177ddc",
      color: theme.palette.common.white,
      whiteSpace: "nowrap",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      width: "100%",
    },
  }));

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
      // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell>{t("profile.profileSettings.profilePicture")}</StyledTableCell>
            <StyledTableCell>{t("profile.profileSettings.firstName")}</StyledTableCell>
            <StyledTableCell>{t("profile.profileSettings.lastName")}</StyledTableCell>
            <StyledTableCell>{t("jobs.applicationCard.appliedOn")}</StyledTableCell>
            <StyledTableCell>{t("hasAppliedBefore")}</StyledTableCell>
            <StyledTableCell>{t("education")}</StyledTableCell>
            <StyledTableCell>{t("jobs.applicantSidebar.experience.title")}</StyledTableCell>
            <StyledTableCell>{t("skills")}</StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {localApplications.map((application) => {
            const isFavorite = application.status === "favourite";
            const isDismissed = application.is_dismissed;
            return (
              <TableRow key={application.id}>
                <TableCell>{isFavorite ? (
                  <Tooltip
                    title={
                      t("removeFromFavourites")
                    }
                    placement="top"
                  >
                    <IconButton onClick={() => toggleFavorite(application.id)}>
                      <Bookmark sx={{fontSize: 20}}/>
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      t("addToFavourites")
                    }
                    placement="top"
                  >
                    <IconButton onClick={() => toggleFavorite(application.id)}>
                      <BookmarkAddOutlined sx={{fontSize: 20}}/>
                    </IconButton>
                  </Tooltip>
                )}</TableCell>
                <TableCell>
                  <div className="ApplicationCard__person-image-wrapper">
                    <img
                      src={application.applicant?.profile?.picture_url}
                      alt=""
                      className="ApplicationCard__person-image-table"
                    />
                    {!application.is_viewed && (
                      <span className="ApplicationCard__person-status-table">{t("new")}</span>
                    )}
                  </div>
                </TableCell>
                <TableCell>{application.applicant.profile.first_name}</TableCell>
                <TableCell>{application.applicant.profile.last_name}</TableCell>
                <TableCell>
                  {new Date(application.applied_on).toLocaleDateString("en-US")}
                </TableCell>
                <TableCell>
                  {application.history.length > 0 ? (
                    <Typography color="primary">✔</Typography>
                  ) : (
                    <Typography color="error">✘</Typography>
                  )}
                </TableCell>
                <TableCell>
                  {application.education.length > 0 ? (
                    <Typography color="primary">✔</Typography>
                  ) : (
                    <Typography color="error">✘</Typography>
                  )}
                </TableCell>
                <TableCell>
                  {application.experience.length > 0 ? (
                    <Typography color="primary">✔</Typography>
                  ) : (
                    <Typography color="error">✘</Typography>
                  )}
                </TableCell>
                <TableCell>
                  {application.skills.length > 0 ? (
                    <Typography color="primary">✔</Typography>
                  ) : (
                    <Typography color="error">✘</Typography>
                  )}
                </TableCell>
                <TableCell>
                  <div style={{display: "flex", alignItems: "center", gap: "0.5rem"}}>

                    <Tooltip
                      title={
                        application.comment ? application.comment : t("addComment")
                      }
                      placement="top"
                    >
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={() => {
                          setCommentSelectedApplicant(application);
                        }}
                      >
                        {application.comment ?
                          <MarkChatUnread
                            sx={{fontSize: 20}}/>
                          : <ChatBubbleOutline
                            sx={{fontSize: 20}}
                          />
                        }
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        setSelectedApplicant(application);
                        handleClick(event);
                      }}
                    >
                      <PlaylistAdd/>
                    </IconButton>
                    <Tooltip
                      title={
                        isDismissed ? t("unDismiss") : t("dismiss")
                      }
                      placement="top"
                    >
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={() => {
                          toggleIsDismissed(application.id);
                        }}>
                        {isDismissed ? <Visibility sx={{fontSize: 20}} style={{color: "gray"}}/> :
                          <VisibilityOff sx={{fontSize: 20}} style={{color: "gray"}}/>}
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell>
                  <div
                    className="ApplicationCard__back-icon-wrapper-table"
                    onClick={() => {
                      onViewClick(application);
                      if (!application.is_viewed) {
                        toggleIsViewed(application.id);
                      }
                    }}
                  >
                    <span style={{fontWeight: '600'}}>{t("jobs.applicationCard.view")}</span>
                    <ChevronRight/>
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Popover
        id="long-menu"
        anchorEl={listAnchorEl}
        open={listOpen}
        onClose={handleListClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={{
          paper: {
            style: {
              maxHeight: 48 * 4.5,
              width: 'auto',
              padding: '0.5rem',
            },
          },
        }}
      >
        <MenuItem
          key={"create-list"}
          onClick={() => {
            setShowCreateListModal(true);
            handleListClose();
          }}
        >
          <ListItemIcon>
            <AddCircle/>
          </ListItemIcon>
          {t("createNewList")}
        </MenuItem>

        {lists.map((list: any) => (
            <MenuItem
              key={list.id}
              onClick={() => {
                const data = {
                  user_id: selectedApplicant.applicant?.id,
                }
                apiCall("POST", `search/folders/${list.id}/add-remove-user/`, data)
                  .then((res) => {
                    handleListClose();
                    globalCtx.openMessageModal(
                      "success",
                      t("userAddedToList"),
                    );
                  })
                  .catch((err) => {
                    handleListClose();
                    const errorMessage = convertRestApiErrorCodes(
                      err?.response?.data?.code,
                      t,
                    );
                    globalCtx.openMessageModal("warning", errorMessage);
                  });
              }}
            >
              {list.name}
            </MenuItem>
          )
        )}
      </Popover>
      {openCommentModal && (
        <CommentModal
          onCancel={() => {
            setOpenCommentModal(false)
          }}
          onSubmit={
            setApplicantComment
          }
          applicantId={commentSelectedApplicant.id}
          comment={commentSelectedApplicant.comment}
        />
      )}
      {showCreateListModal && (
        <CreateListModal
          onCancel={() => setShowCreateListModal(false)}
          onSubmit={(values) => {
            console.log(values);
            setShowCreateListModal(false);
            createList(values);
          }}
          buttonLabel={t("createList")}
          hideCancelBtn={true}
          title={t("fillTheFormToCreateList")}
        />
      )}
    </TableContainer>

  );
};

export default ApplicationTable;