import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useCallback} from "react";

export const useShortTermFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const { t } = useTranslation("common");
  const memoizedT = useCallback(t, [t]);
  let duplicateFromShortTermJob = JSON.parse(localStorage.getItem("duplicateFromShortTermJob") || "{}");

  return useFormik({
    initialValues: {
      job_title_text: duplicateFromShortTermJob.job_title_text? duplicateFromShortTermJob.job_title_text : "",
      ...opts?.initialValues,
      hourly_rate: duplicateFromShortTermJob.pay_rate? duplicateFromShortTermJob.pay_rate : "",
      seats: duplicateFromShortTermJob.seats? duplicateFromShortTermJob.seats : "",
      description: duplicateFromShortTermJob.description? duplicateFromShortTermJob.description : "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      hourly_rate: Yup.number()
        .min(2, memoizedT("jobs.newShortTermJob.jobDetails.hourlyRate.min"))
        .required(
          memoizedT("jobs.newShortTermJob.jobDetails.hourlyRate.required"),
        ),
      seats: Yup.number()
        .required(memoizedT("jobs.newShortTermJob.jobDetails.seats.required"))
        .min(1),
      description: Yup.string()
        .required(
          memoizedT("jobs.newShortTermJob.jobDetails.description.required"),
        )
        .min(5, memoizedT("jobs.newShortTermJob.jobDetails.description.min"))
        .max(
          5000,
          memoizedT("jobs.newShortTermJob.jobDetails.description.max"),
        ),
      job_title_text: Yup.string()
          .required(memoizedT("jobs.newLongTermJob.jobTitleRequired"))
          .min(5, memoizedT("jobs.newLongTermJob.jobTitleMin"))
          .max(60, memoizedT("jobs.newLongTermJob.jobTitleMax")),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
