import React, {FunctionComponent} from 'react';
import styles from './Cards.module.css';
import {AdsClick, Man, Preview, PreviewOutlined, Streetview, TouchApp, Visibility, Woman} from "@mui/icons-material";
import {ClickAwayListener} from "@mui/material";

interface CardsProps {
  firstCardTitle: string;
  firstCardValue: number | any;
  firstCardIcon: any;
  secondCardTitle: string
  secondCardValue: number | string;
  secondCardIcon: any;
  thirdCardTitle: string;
  thirdCardValue: number | string;
  thirdCardIcon: any;
  fourthCardTitle: string;
  fourthCardValue: number | string;
  fourthCardIcon: any;
  hasAdvancedPermissions?: boolean;
}


const Cards: FunctionComponent<CardsProps> = ({
                                                firstCardTitle,
                                                firstCardValue,
                                                firstCardIcon: FirstCardIcon,
                                                secondCardTitle,
                                                secondCardValue,
                                                secondCardIcon: SecondCardIcon,
                                                thirdCardTitle,
                                                thirdCardValue,
                                                thirdCardIcon: ThirdCardIcon,
                                                fourthCardTitle,
                                                fourthCardValue,
                                                fourthCardIcon: FourthCardIcon,
                                                hasAdvancedPermissions,
                                              }) => {
  return (
    <div className={styles.cards}>
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.text}>
            <div className={styles.div}>{firstCardValue}</div>
            <div className={styles.totalUsers}>{firstCardTitle}</div>
          </div>
          <div className={styles.icon}>
            <FirstCardIcon sx={{ fontSize: 20 }} style={{ color: "gray" }} />
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.text}>
            <div className={styles.div}>{secondCardValue}</div>
            <div className={styles.totalUsers}>{secondCardTitle}</div>
          </div>
          <div className={styles.icon}>
            <SecondCardIcon sx={{ fontSize: 20 }} style={{ color: "gray" }} />
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.text}>
            <div
              className={`${styles.div} ${
                !hasAdvancedPermissions ? styles.blurWrapper : ""
              }`}
            >
              {thirdCardValue}
            </div>
            <div className={styles.totalUsers}>{thirdCardTitle}</div>
          </div>
          <div className={styles.icon}>
            <ThirdCardIcon sx={{ fontSize: 20 }} style={{ color: "gray" }} />
          </div>
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.card}>
        <div className={styles.top}>
          <div className={styles.text}>
            <div
              className={`${styles.div} ${
                !hasAdvancedPermissions ? styles.blurWrapper : ""
              }`}
            >
              {fourthCardValue}
            </div>
            <div className={styles.totalUsers}>{fourthCardTitle}</div>
          </div>
          <div className={styles.icon}>
            <FourthCardIcon sx={{ fontSize: 20 }} style={{ color: "gray" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;