import {useGlobalContext} from "../../context/GlobalContext/GlobalContext";
import {useAuthContext} from "../../context/AuthContext/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "./JobAnalytics.scss";

import {useTheme} from '@mui/material/styles';
import Cards from "./Cards/Cards";
import Table from "./Table/Table";
import TwoCards from "./TwoCards/TwoCards";
import {
  AdsClick,
  AssignmentReturn,
  KeyboardReturn,
  Man,
  Preview,
  TouchApp,
  Visibility,
  Woman
} from "@mui/icons-material";
import {BarChart} from '@mui/x-charts/BarChart';
import {handleErrors} from "../../lib/helpers/handleErrors";
import {apiCall} from "../../api/Api";
import LoadingComponent from "../../components/shared/LoadingComponent/LoadingComponent";
import {useResponsiveDimensions} from "../../lib/hooks/useResponsiveDimensions";
import SingleCard from "./SingleCard/SingleCard";
import info from "../../assets/svg/info.svg";


const JobAnalytics = () => {
  const {jobId} = useParams();
  let selectedLanguage = localStorage.getItem("lang");
  const globalCtx = useGlobalContext();
  const {t} = useTranslation("common");
  const navigate = useNavigate();
  const authCtx = useAuthContext();
  const isMobile = useResponsiveDimensions().isMobile;
  const [isLoading, setIsLoading] = useState(false);
  const [jobAnalytics, setJobAnalytics] = useState<any>({});
  const [basicJobAnalytics, setBasicJobAnalytics] = useState<any>({});
  const [jobDetails, setJobDetails] = useState<any>({});
  const theme = useTheme();
  const interactionTimeline = jobAnalytics.interaction_timeline || {};
  const days = Object.keys(interactionTimeline).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
  const views = days.map(day => interactionTimeline[day].views);
  const clicks = days.map(day => interactionTimeline[day].clicks);
  const [hasAdvancedPermission, setHasAdvancedPermission] = useState(
    authCtx.isOrganizationBusinessPlusOrPro || !authCtx.isOrganizationCreatedMonthAgo || false
  );

  const getBasicJobAnalytics = () => {
    apiCall("GET", `/analytics/b2b/job/${jobId}/`).then((res) => {
      setBasicJobAnalytics(res.data);
      setIsLoading(false);
    }).catch((error: any) => {
        const errorMessage = handleErrors(error, t);
        globalCtx.openMessageModal("error", errorMessage);
        setIsLoading(false);
      }
    );
  }

  const getAdvancedJobAnalytics = () => {
    apiCall("GET", `/analytics/b2b/job/${jobId}/advanced/`).then((res) => {
      setIsLoading(false);
      setJobAnalytics(res.data);
      console.log(res);
    }).catch((error: any) => {
      const errorMessage = handleErrors(error, t);
      globalCtx.openMessageModal("error", errorMessage);
      setIsLoading(false);
    });
  }

  const getJobDetails = () => {
    apiCall("GET", `/b2c/jobs/${jobId}/`).then((res) => {
      setJobDetails(res.data);
      console.log(res);
      if (hasAdvancedPermission) {
        getAdvancedJobAnalytics();
        return;
      }
      if (res.data && res.data.is_promoted && res.data.is_active) {
        setHasAdvancedPermission(true);
        getAdvancedJobAnalytics();
      } else {
        getBasicJobAnalytics();
      }
    }).catch((error: any) => {
      const errorMessage = handleErrors(error, t);
      globalCtx.openMessageModal("error", errorMessage);
    });
  }

  useEffect(() => {
    if (jobId) {
      setIsLoading(true);
      getJobDetails();
      // if (!hasAdvancedPermission) {
      //   getBasicJobAnalytics();
      // } else {
      //   getAdvancedJobAnalytics();
      // }
    }
  }, [jobId]);

  return (
    <div className="JobAnalytics">
      <p className="JobAnalytics__title">
        <span>{t("jobAnalytics")}</span> {""}
      </p>
      {jobDetails && Object.keys(jobDetails).length > 0 && (
        <p className="JobAnalytics__subtitle">
          {t("detailsForJob")}: <span>({jobDetails.seats}) {jobDetails.job_title_text} në {jobDetails.organization_unit.name}</span>
        </p>
      )}
      <div className="JobAnalytics__hint-info">
        <img
          src={info}
          alt="info icon"
          className="JobAnalytics__hint-icon"
        />
        <div className="JobAnalytics__hint-text">
          {t("dataAreAvailableFrom")}
        </div>
      </div>

      {isLoading && <LoadingComponent/>}

      {basicJobAnalytics && Object.keys(basicJobAnalytics).length > 0 && (
        <div>
          {!isMobile ? (

            <Cards
              firstCardTitle={t("totalViews")}
              firstCardValue={basicJobAnalytics.view_count}
              firstCardIcon={Visibility}
              secondCardTitle={t("totalClicks")}
              secondCardValue={basicJobAnalytics.click_count}
              secondCardIcon={AdsClick}
              thirdCardTitle={t("uniqueViews")}
              thirdCardValue={0.000}
              thirdCardIcon={Preview}
              fourthCardTitle={t("uniqueClicks")}
              fourthCardValue={0.000}
              fourthCardIcon={TouchApp}
              hasAdvancedPermissions={hasAdvancedPermission}
            />
          ) : (
            <div className="JobAnalytics__cards-row-container">
              <SingleCard
                firstCardTitle={t("totalViews")}
                firstCardValue={basicJobAnalytics.view_count}
                firstCardIcon={Visibility}
                hasAdvancedPermissions={true}/>

              <SingleCard
                firstCardTitle={t("totalClicks")}
                firstCardValue={basicJobAnalytics.click_count}
                firstCardIcon={AdsClick}
                hasAdvancedPermissions={true}/>

              <SingleCard
                firstCardTitle={t("uniqueViews")}
                firstCardValue={0.000}
                firstCardIcon={Preview}
                hasAdvancedPermissions={false}/>

              <SingleCard
                firstCardTitle={t("uniqueClicks")}
                firstCardValue={0.000}
                firstCardIcon={TouchApp}
                hasAdvancedPermissions={false}/>
            </div>
          )}
          {!hasAdvancedPermission && (
            <p className="JobAnalytics__subscribe-subtitle" onClick={() => navigate("/plans")}>
              {t("youNeedToSubscribeToSeeAdvancedAnalytics")}
            </p>
          )}

          {!isMobile ? (

            <div className="JobAnalytics__user-details-container">

              <Table
                firstRowTitle="16-25"
                firstRowValue={0}
                secondRowTitle="26-35"
                secondRowValue={0}
                thirdRowTitle="36-45"
                thirdRowValue={0}
                fourthRowTitle="46-55"
                fourthRowValue={0}
                fifthRowTitle="Other"
                fifthRowValue={0}
                hasAdvancedPermissions={hasAdvancedPermission}
              />

              <div className="JobAnalytics__user-details-container-right">

                <TwoCards
                  firstCardTitle={t("males")}
                  firstCardValue={0}
                  secondCardTitle={t("females")}
                  secondCardValue={0}
                  firstCardIcon={Man}
                  secondCardIcon={Woman}
                  hasAdvancedPermissions={hasAdvancedPermission}
                />

                <TwoCards
                  firstCardTitle={t("viewToClickRatio")}
                  firstCardValue={0}
                  secondCardTitle={t("clickToApplyRatio")}
                  secondCardValue={0}
                  firstCardIcon={AssignmentReturn}
                  secondCardIcon={KeyboardReturn}
                  hasAdvancedPermissions={hasAdvancedPermission}
                />

              </div>
            </div>
          ) : (
            <div className="JobAnalytics__cards-row-container">
              <Table
                firstRowTitle="16-25"
                firstRowValue={0}
                secondRowTitle="26-35"
                secondRowValue={0}
                thirdRowTitle="36-45"
                thirdRowValue={0}
                fourthRowTitle="46-55"
                fourthRowValue={0}
                fifthRowTitle="Other"
                fifthRowValue={0}
                hasAdvancedPermissions={hasAdvancedPermission}
              />

              <SingleCard
                firstCardTitle={t("males")}
                firstCardValue={0}
                firstCardIcon={Man}
                hasAdvancedPermissions={false}/>

              <SingleCard
                firstCardTitle={t("females")}
                firstCardValue={0}
                firstCardIcon={Woman}
                hasAdvancedPermissions={false}/>

              <SingleCard
                firstCardTitle={t("viewToClickRatio")}
                firstCardValue={0}
                firstCardIcon={AssignmentReturn}
                hasAdvancedPermissions={false}/>

              <SingleCard
                firstCardTitle={t("clickToApplyRatio")}
                firstCardValue={0}
                firstCardIcon={KeyboardReturn}
                hasAdvancedPermissions={false}/>
            </div>
          )}

          {
            jobAnalytics.interaction_timeline && (
              <div className="JobAnalytics__chart-container">
                <BarChart
                  xAxis={[{
                    scaleType: 'band',
                    data: days.map(day => new Date(day).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short'
                    }))
                  }]}
                  series={[
                    {data: views, label: t("views")},
                    {data: clicks, label: t("clicks")}
                  ]}
                  height={300}
                  borderRadius={5}
                />
              </div>
            )
          }
          <div className="JobAnalytics__chart-container-blur">
            <BarChart
              xAxis={[{
                scaleType: 'band',
                data: ['21 Nov', '22 Nov', '23 Nov', '24 Nov', '25 Nov', '26 Nov', '27 Nov', '28 Nov', '29 Nov', '30 Nov']
              }]}
              series={[
                {data: [15, 25, 35, 45, 55, 65, 75, 85, 95, 105], label: t("views")},
                {data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100], label: t("clicks")}
              ]} height={300}
              borderRadius={5}
              tooltip={{trigger: 'none'}}
            />
          </div>
          <p className="JobAnalytics__chart-title">{t("interactionTimeline")}</p>
        </div>

      )}

      {
        jobAnalytics && Object.keys(jobAnalytics).length > 0 && (
          <div>
            {!isMobile ? (

              <Cards
                firstCardTitle={t("totalViews")}
                firstCardValue={jobAnalytics.total_views}
                firstCardIcon={Visibility}
                secondCardTitle={t("totalClicks")}
                secondCardValue={jobAnalytics.total_clicks}
                secondCardIcon={AdsClick}
                thirdCardTitle={t("uniqueViews")}
                thirdCardValue={jobAnalytics.unique_views}
                thirdCardIcon={Preview}
                fourthCardTitle={t("uniqueClicks")}
                fourthCardValue={jobAnalytics.unique_clicks}
                fourthCardIcon={TouchApp}
                hasAdvancedPermissions={hasAdvancedPermission}
              />
            ) : (
              <div className="JobAnalytics__cards-row-container">
                <SingleCard
                  firstCardTitle={t("totalViews")}
                  firstCardValue={jobAnalytics.total_views}
                  firstCardIcon={Visibility}
                  hasAdvancedPermissions={true}/>

                <SingleCard
                  firstCardTitle={t("totalClicks")}
                  firstCardValue={jobAnalytics.total_clicks}
                  firstCardIcon={AdsClick}
                  hasAdvancedPermissions={true}/>

                <SingleCard
                  firstCardTitle={t("uniqueViews")}
                  firstCardValue={jobAnalytics.unique_views}
                  firstCardIcon={Preview}
                  hasAdvancedPermissions={true}/>

                <SingleCard
                  firstCardTitle={t("uniqueClicks")}
                  firstCardValue={jobAnalytics.unique_clicks}
                  firstCardIcon={TouchApp}
                  hasAdvancedPermissions={true}/>
              </div>
            )}

            {!isMobile ? (

              <div className="JobAnalytics__user-details-container">

                {jobAnalytics.age_group_distribution && (
                  <Table
                    firstRowTitle="16-25"
                    firstRowValue={jobAnalytics.age_group_distribution["16-25"]}
                    secondRowTitle="26-35"
                    secondRowValue={jobAnalytics.age_group_distribution["26-35"]}
                    thirdRowTitle="36-45"
                    thirdRowValue={jobAnalytics.age_group_distribution["36-45"]}
                    fourthRowTitle="46-55"
                    fourthRowValue={jobAnalytics.age_group_distribution["46-55"]}
                    fifthRowTitle="Other"
                    fifthRowValue={jobAnalytics.age_group_distribution["Other"]}
                    hasAdvancedPermissions={hasAdvancedPermission}
                  />
                )}

                <div className="JobAnalytics__user-details-container-right">

                  {jobAnalytics.gender_distribution && Object.keys(jobAnalytics.gender_distribution).length > 0 && (
                    <TwoCards
                      firstCardTitle={t("males")}
                      firstCardValue={parseFloat((jobAnalytics.gender_distribution["M"] || 0).toFixed(2))}
                      secondCardTitle={t("females")}
                      secondCardValue={parseFloat((jobAnalytics.gender_distribution["F"] || 0).toFixed(2))}
                      firstCardIcon={Man}
                      secondCardIcon={Woman}
                      hasAdvancedPermissions={hasAdvancedPermission}
                    />
                  )}

                  {jobAnalytics.engagement_metrics && (
                    <TwoCards
                      firstCardTitle={t("viewToClickRatio")}
                      firstCardValue={jobAnalytics.engagement_metrics.view_to_click_conversion_rate}
                      secondCardTitle={t("clickToApplyRatio")}
                      secondCardValue={jobAnalytics.engagement_metrics.click_to_apply_conversion_rate}
                      firstCardIcon={AssignmentReturn}
                      secondCardIcon={KeyboardReturn}
                      hasAdvancedPermissions={hasAdvancedPermission}
                    />
                  )}

                </div>
              </div>
            ) : (
              <div className="JobAnalytics__cards-row-container">
                {jobAnalytics.age_group_distribution && (
                  <Table
                    firstRowTitle="16-25"
                    firstRowValue={jobAnalytics.age_group_distribution["16-25"]}
                    secondRowTitle="26-35"
                    secondRowValue={jobAnalytics.age_group_distribution["26-35"]}
                    thirdRowTitle="36-45"
                    thirdRowValue={jobAnalytics.age_group_distribution["36-45"]}
                    fourthRowTitle="46-55"
                    fourthRowValue={jobAnalytics.age_group_distribution["46-55"]}
                    fifthRowTitle="Other"
                    fifthRowValue={jobAnalytics.age_group_distribution["Other"]}
                    hasAdvancedPermissions={hasAdvancedPermission}
                  />
                )}

                <SingleCard
                  firstCardTitle={t("males")}
                  firstCardValue={parseFloat((jobAnalytics.gender_distribution["M"] || 0).toFixed(2))}
                  firstCardIcon={Man}
                  hasAdvancedPermissions={true}/>

                <SingleCard
                  firstCardTitle={t("females")}
                  firstCardValue={parseFloat((jobAnalytics.gender_distribution["F"] || 0).toFixed(2))}
                  firstCardIcon={Woman}
                  hasAdvancedPermissions={true}/>

                <SingleCard
                  firstCardTitle={t("viewToClickRatio")}
                  firstCardValue={jobAnalytics.engagement_metrics.view_to_click_conversion_rate}
                  firstCardIcon={AssignmentReturn}
                  hasAdvancedPermissions={true}/>

                <SingleCard
                  firstCardTitle={t("clickToApplyRatio")}
                  firstCardValue={jobAnalytics.engagement_metrics.click_to_apply_conversion_rate}
                  firstCardIcon={KeyboardReturn}
                  hasAdvancedPermissions={true}/>
              </div>
            )}


            {jobAnalytics.interaction_timeline && (
              <div className="JobAnalytics__chart-container">
                <BarChart
                  xAxis={[{
                    scaleType: 'band',
                    data: days.map(day => new Date(day).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: 'short'
                    }))
                  }]}
                  series={[
                    {data: views, label: t("views")},
                    {data: clicks, label: t("clicks")}
                  ]}
                  height={300}
                  borderRadius={5}
                />
              </div>
            )}
            <p className="JobAnalytics__chart-title">{t("interactionTimeline")}</p>

          </div>
        )
      }
    </div>
  )
    ;
}

export default JobAnalytics;