import {useFormik} from "formik";
import * as Yup from "yup";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

export const useCreateListFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const {t} = useTranslation("common");

  const memoizedT = useCallback(t, [t]);

  return useFormik({
    initialValues: {
      listName: "",
      listDescription: "",
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      listName: Yup.string().required(
        memoizedT("listNameRequired"),
      ),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
