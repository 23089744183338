import React from "react";

import {useResponsiveDimensions} from "../../../lib/hooks/useResponsiveDimensions";
import {useTranslation} from "react-i18next";
import ModalContainer from "../ModalContainer/ModalContainer";
import closeIcon from "../../../assets/svg/close-white.svg";
import plusIcon from "../../../assets/svg/plus-icon.svg";
import { analytics } from "../../../firebaseConfig";
import { logEvent } from "firebase/analytics";

import "./SubscribeForJobsModal.scss";
import {CalendarMonth, LowPriority, Notifications, PostAdd} from "@mui/icons-material";
import {useAuthContext} from "../../../context/AuthContext/AuthContext";

interface SubscribeForJobsModalProps {
  onUpgradeClick: () => void;
  onCloseClick: () => void;
}

const SubscribeForJobsModal = (props: SubscribeForJobsModalProps) => {
  const {onUpgradeClick, onCloseClick} = props;
  const isMobile = useResponsiveDimensions().isMobile;
  const {t} = useTranslation("common");
  const authCtx = useAuthContext();
  logEvent(analytics, "trial_ended_modal_opened", {
    organization: authCtx.localOrganization.name || "unknown",
  });

  return (
    <ModalContainer>
      <div className="SubscribeForJobsModal">
        <img
          alt="pic"
          className="SubscribeForJobsModal__close"
          src={closeIcon}
          onClick={() => {
            onCloseClick();
          }}
        />
        <div className="SubscribeForJobsModal__body">
          <div className="SubscribeForJobsModal__left">
            <p className="SubscribeForJobsModal__title">
              {t("yourFreeFirstMonthIsOver")} <br/>{" "}
              {t("isOver")}
            </p>

            <p className="SubscribeForJobsModal__description">
              {t("continueToRecruit")}
              &nbsp;
            </p>
            {!isMobile && (
              <div className="SubscribeForJobsModal__button-container">
                <div
                  className="SubscribeForJobsModal__button"
                  onClick={() => {
                    onUpgradeClick();
                  }}
                >
                  <div className="SubscribeForJobsModal__button-round">
                    <img
                      alt="pic"
                      src={plusIcon}
                      className="SubscribeForJobsModal__button-round--plus"
                    />
                  </div>
                  <p className="SubscribeForJobsModal__button-text">
                    {t("postJob")}
                  </p>
                </div>
              </div>
            )}
          </div>

          <div className="SubscribeForJobsModal__center">
            <div className="SubscribeForJobsModalCart__header-basic">
              <p className="SubscribeForJobsModalCart__title">
                {t("basic")} 9.99€
              </p>
            </div>
            <div className="SubscribeForJobsModalCart">
              <div className="SubscribeForJobsModalCart__container">

                <CalendarMonth sx={{fontSize: 30}} color="primary" className={"SubscribeForJobsModalCart__icon"}/>
                <p className="SubscribeForJobsModalCart__title">
                  {t("jobWillBeActiveFor30Days")}
                </p>
              </div>
              <div className="SubscribeForJobsModalCart__shadow"></div>
              <div className="SubscribeForJobsModalCart__shadowTwo"></div>
            </div>
            {" "}
          </div>

          <div className="SubscribeForJobsModal__right">
            <div className="SubscribeForJobsModalCart__header-pro">
              <p className="SubscribeForJobsModalCart__title">
                {t("pro")} 59.99€
              </p>
            </div>
            <div className="SubscribeForJobsModalCart">
              <div className="SubscribeForJobsModalCart__container">
                <LowPriority sx={{fontSize: 30, transform: 'scaleY(-1)'}} color="primary"
                             className="SubscribeForJobsModalCart__icon"/>                <p
                className="SubscribeForJobsModalCart__title">
                {t("promotion")}
              </p>
                <div className="SubscribeForJobsModalCart__number">+</div>
              </div>
              <div className="SubscribeForJobsModalCart__shadow"></div>
              <div className="SubscribeForJobsModalCart__shadowTwo"></div>
            </div>
            {" "}
            <div className="SubscribeForJobsModalCart">
              <div className="SubscribeForJobsModalCart__container">
                <PostAdd sx={{fontSize: 30}} color={"primary"} className="SubscribeForJobsModalCart__icon"/>
                <p className="SubscribeForJobsModalCart__title">
                  {t("socialMediaPost")}
                </p>
                <div className="SubscribeForJobsModalCart__number">+</div>
              </div>
              <div className="SubscribeForJobsModalCart__shadow"></div>
              <div className="SubscribeForJobsModalCart__shadowTwo"></div>
            </div>
            {" "}
            <div className="SubscribeForJobsModalCart">
              <div className="SubscribeForJobsModalCart__container">
                <Notifications sx={{fontSize: 30}} color={"primary"} className="SubscribeForJobsModalCart__icon"/>
                <p className="SubscribeForJobsModalCart__title">
                  {t("pushNotifications")}
                </p>
                <div className="SubscribeForJobsModalCart__number">+</div>
              </div>
              <div className="SubscribeForJobsModalCart__shadow"></div>
              <div className="SubscribeForJobsModalCart__shadowTwo"></div>
            </div>
            {" "}
          </div>
          {isMobile && (
            <div className="SubscribeForJobsModal__button-container">
              <div
                className="SubscribeForJobsModal__button"
                onClick={() => {
                  onUpgradeClick();
                }}
              >
                <div className="SubscribeForJobsModal__button-round">
                  <img
                    alt="pic"
                    src={plusIcon}
                    className="SubscribeForJobsModal__button-round--plus"
                  />
                </div>
                <p className="SubscribeForJobsModal__button-text">
                  {t("postJob")}
                </p>
              </div>
            </div>
          )}
        </div>

      </div>
    </ModalContainer>
  );
};

export default SubscribeForJobsModal;
