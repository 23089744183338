import React, {useEffect, useState} from "react";
import verified from "../../../assets/svg/verified-icon-green.svg";
import cs from "classnames";
import acceptedIcon from "../../../assets/svg/accepted-icon.svg";
import cancelledIcon from "../../../assets/svg/cancelled-icon.svg";
import {useTranslation} from "react-i18next";

import "./SearchCard.scss";
import {getTimeBetweenDates} from "../../../lib/helpers/getTimeBetweenDates";
import {
  AddCircle,
  ChevronRight, EngineeringOutlined, PlaylistAdd, PlaylistRemove,
  SchoolOutlined,
  WorkOutlineOutlined
} from "@mui/icons-material";
import {apiCall} from "../../../api/Api";
import Tooltip from "@mui/material/Tooltip";
import CommentModal from "../../../components/shared/CommentModal/CommentModal";
import {Chip, ListItemIcon, MenuItem, Popover, Stack} from "@mui/material";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {convertRestApiErrorCodes} from "../../../lib/helpers/handleErrors";
import CreateListModal from "../../../components/shared/CreateListModal/CreateListModal";

const SearchCard = (props: any) => {
  const {person, jobName, setSelectedApplicant, setOpenSidebar, cardIsInList, listId} = props;
  const {t} = useTranslation("common");
  const globalCtx = useGlobalContext();
  const [isFavorite, setIsFavorite] = useState(person.status === "favourite");
  const [isViewed, setIsViewed] = useState(person.is_viewed);
  const [isDismissed, setIsDismissed] = useState(person.is_dismissed);
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [listAnchorEl, setListAnchorEl] = useState<null | HTMLElement>(null);
  const listOpen = Boolean(listAnchorEl);
  const [lists, setLists] = useState<any>([]);
  const [showCreateListModal, setShowCreateListModal] = useState<boolean>(false);

  const handleListClose = () => {
    setListAnchorEl(null);
  }

  const setApplicantComment = (comment: string) => {
    person.comment = comment;
  }

  const getLists = () => {
    try {
      apiCall("get", "search/folders/").then((res) => {
        setLists(res.data);
      }).catch((err) => {
          const errorMessage = convertRestApiErrorCodes(
            err?.response?.data?.code,
            t,
          );
          globalCtx.openMessageModal("error", errorMessage);
        }
      );
    } catch (err) {
    }
  }

  const createList = (data: any) => {
    try {
      apiCall("POST", `search/folders/`, data)
        .then((res) => {
          setLists([...lists, res.data]);

          globalCtx.openMessageModal(
            "success",
            t("listCreated"),
          );
        })
        .catch((err) => {
            const errorMessage = convertRestApiErrorCodes(
              err?.response?.data?.code,
              t,
            );
            globalCtx.openMessageModal("error", errorMessage);
          }
        );
    } catch (err) {
    }
  }

  useEffect(() => {
    setIsFavorite(person.status === "favourite");
    setIsViewed(person.is_viewed);
    setIsDismissed(person.is_dismissed);
  }, [person]);


  const toggleIsDismissed = () => {
    apiCall("POST", `b2b/applications/toggle-dismiss/${person.id}/`)
      .then((res) => {
        setIsDismissed(!isDismissed);
        // refresh the list
        props.refreshList();
      })
      .catch((err) => {
      });
  }

  const toggleFavorite = () => {
    apiCall("POST", `b2b/applications/toggle-favourite/${person.id}/`)
      .then((res) => {
        setIsFavorite(!isFavorite);
        // props.refreshList();
        person.status = isFavorite ? "applied" : "favourite";
      })
      .catch((err) => {
      });
  }

  function formatDuration(timeDiff: TimeDifference) {
    const years = Math.floor(timeDiff.days / 365);
    const months = Math.floor((timeDiff.days % 365) / 30); // Rough calculation
    return `${years ? `${years} vite ` : ""}${months ? `${months} muaj` : ""}`;
  }

  interface TimeDifference {
    days: number;
  }

  interface ExperienceItem {
    id?: number;
    job_title?: string;
    company_name?: string;
    start_date: string;
    end_date?: string | null;
  }

  interface EducationItem {
    id?: string;
    degree?: string;
    description?: string;
    end_date?: string | null;
    field_of_study?: string;
    school_name?: string;
    start_date: string;
  }

  const mostRecentExperience = person.experience.reduce(
    (latest: ExperienceItem, item: ExperienceItem) => {
      if (!latest.end_date) return latest;
      if (!item.end_date) return item;
      const latestEndDate = new Date(latest.end_date);
      const currentEndDate = new Date(item.end_date);
      return currentEndDate > latestEndDate ? item : latest;
    },
    person.experience[0] as ExperienceItem
  );

  // Find the most recent education
  const mostRecentEducation = person.education.reduce(
    (latest: EducationItem, item: EducationItem) => {
      if (!latest.end_date) return latest;
      if (!item.end_date) return item;
      const latestEndDate = new Date(latest.end_date);
      const currentEndDate = new Date(item.end_date);
      return currentEndDate > latestEndDate ? item : latest;
    },
    person.education[0] as EducationItem
  );

  return (
    <section className="SearchCard">
      <div
        className={cs(
          "SearchCard__header",
          person.status === "accepted" && "SearchCard__header--accepted",
          person.status === "favourite" && "SearchCard__header--favourite",
          person.status === "cancelled" && "SearchCard__header--cancelled",
        )}
      >
        <div className="SearchCard__person-image-wrapper">
          <img
            src={person.profile?.picture_url}
            alt=""
            className="SearchCard__person-image"
          />
        </div>

        <div>
          <div className="SearchCard__name-container">
            <h2
              className={cs(
                "SearchCard__name",
                person.status === "accepted" && "SearchCard__name--accepted",
              )}
            >
              {`${person.profile?.first_name} ${person.profile?.last_name}`}
            </h2>
            {person.verified && (
              <img
                src={verified}
                alt="verified icon"
                className="SearchCard__verified-icon"
              />
            )}
          </div>
          <div className="SearchCard__role">
            {person.profile?.address}
          </div>
        </div>
      </div>
      <div className="SearchCard__title">
        <div className="SearchCard__title_icon">
          <WorkOutlineOutlined/>
          {t("jobs.applicantSidebar.experience.title")}
        </div>
      </div>
      <div className="SearchCard__body-main-container">
        {person?.experience && person.experience.length > 0 ? (
          mostRecentExperience && (() => {
            const startDate = new Date(mostRecentExperience.start_date);
            const endDate = mostRecentExperience.end_date
              ? new Date(mostRecentExperience.end_date)
              : new Date();

            return (
              <div
                className="ApplicantSidebar__experience-item"
                key={mostRecentExperience.id || 0}
              >
                <h2 className="ApplicantSidebar__experience-item-title">
                  {mostRecentExperience.job_title}
                </h2>
                <div className="ApplicantSidebar__experience-item-date">
                  {mostRecentExperience.company_name} {"  "}
                  <span>• {formatDuration(getTimeBetweenDates(startDate, endDate))}</span>
                </div>
              </div>
            );
          })()
        ) : (
          <div
            className="ApplicantSidebar__experience-item">
            <h2 className="ApplicantSidebar__no-experience-item-title">
              {t("hasNoExperienceYet")}
            </h2>
            <div className="ApplicantSidebar__experience-item-date">
              &nbsp;
              <span> &nbsp;</span>
            </div>
          </div>
        )}
      </div>
      <div className="SearchCard__title">
        <div className="SearchCard__title_icon">
          <SchoolOutlined/>
          {t("education")}
        </div>
      </div>
      <div className="SearchCard__body-main-container">
        {person?.education && person.education.length > 0 ? (
          mostRecentEducation && (() => (
            <div className="ApplicantSidebar__experience-item" key={mostRecentEducation.id || 0}>
              <h2 className="ApplicantSidebar__experience-item-title">
                {mostRecentEducation.degree} në {mostRecentEducation.field_of_study}
              </h2>
              <p>{mostRecentEducation.school_name}</p>
              <div className="ApplicantSidebar__experience-item-date">
                {`${new Date(mostRecentEducation.start_date).toLocaleString("en-GB", {
                  month: "short",
                  year: "numeric",
                })} - ${mostRecentEducation.end_date === null ? "Present" : new Date(mostRecentEducation.end_date).toLocaleString("en-GB", {
                  month: "short",
                  year: "numeric",
                })}`}
              </div>
            </div>
          ))()
        ) : (
          <div className="ApplicantSidebar__experience-item">
            <h2 className="ApplicantSidebar__no-experience-item-title">
              {t("hasNoEducationYet")}
            </h2>
            <p> &nbsp; </p>
            <div className="ApplicantSidebar__experience-item-date">
              &nbsp;
            </div>
          </div>
        )}
      </div>
      <div className="ApplicationCard__title">
        <div className="ApplicationCard__title_icon">
          <EngineeringOutlined/>
          {t("skills")}
        </div>
      </div>
      {person.skills && person.skills.length > 0 ? (
        <div className="ApplicationCard__header-chips">
          <Stack direction="row" spacing={1}>
            {person.skills.map((skill: any) => (
              <Chip key={skill.id} label={skill.skill_name}/>
            ))}
          </Stack>
        </div>
      ): (
        <div className="ApplicationCard__header-chips">
          <Stack direction="row" spacing={1}>
            <Chip variant="outlined" label={t("noSkillsYet")}/>
          </Stack>
        </div>
      )}
      <div className="SearchCard__button-list">
        <div className="SearchCard__button-row">
          {!cardIsInList ? (
          <div
            className="SearchCard__back-icon-wrapper"
            onClick={(e) => {
              getLists();
              setListAnchorEl(e.currentTarget as HTMLElement);
            }}
          >
            <span style={{fontWeight: '600'}}>{t("addToList")}</span>
            <PlaylistAdd/>
          </div>
          ) : (
            <div
              className="SearchCard__back-icon-wrapper"
              onClick={(e) => {
                const data = {
                  user_id: person.id
                }
                apiCall("DELETE", `search/folders/${listId}/add-remove-user/`, data)
                  .then((res) => {
                    handleListClose();
                    globalCtx.openMessageModal(
                      "success",
                      t("userRemovedFromList"),
                    );
                  })
                  .catch((err) => {
                    handleListClose();
                    const errorMessage = convertRestApiErrorCodes(
                      err?.response?.data?.code,
                      t,
                    );
                    globalCtx.openMessageModal("error", errorMessage);
                  });

              }}
            >
              <span style={{fontWeight: '600'}}>{t("removeUserFromList")}</span>
              <PlaylistRemove/>
            </div>
          )}


          <Popover
            id="long-menu"
            anchorEl={listAnchorEl}
            open={listOpen}
            onClose={handleListClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            slotProps={{
              paper: {
                style: {
                  maxHeight: 48 * 4.5,
                  width: 'auto',
                  padding: '0.5rem',
                },
              },
            }}
          >
            <MenuItem
              key={"create-list"}
              onClick={() => {
                setShowCreateListModal(true);
                handleListClose();
              }}
            >
              <ListItemIcon>
                <AddCircle />
              </ListItemIcon>
              {t("createNewList")}
            </MenuItem>

            {lists.map((list: any) => (
                <MenuItem
                  key={list.id}
                  onClick={() => {
                    const data = {
                      user_id: person.id
                    }
                    apiCall("POST", `search/folders/${list.id}/add-remove-user/`, data)
                      .then((res) => {
                        handleListClose();
                        globalCtx.openMessageModal(
                          "success",
                          t("userAddedToList"),
                        );
                      })
                      .catch((err) => {
                        handleListClose();
                        const errorMessage = convertRestApiErrorCodes(
                          err?.response?.data?.code,
                          t,
                        );
                        globalCtx.openMessageModal("warning", errorMessage);
                      });
                  }}
                >
                  {list.name}
                </MenuItem>
              )
            )}
          </Popover>

          <div
            className="SearchCard__back-icon-wrapper"
            onClick={() => {
              setSelectedApplicant(person);
              setOpenSidebar(true);
            }}
          >
            <span style={{fontWeight: '600'}}>{t("jobs.applicationCard.view")}</span>
            <ChevronRight/>
          </div>
        </div>
      </div>
      {openCommentModal && (
        <CommentModal
          onCancel={() => {
            setOpenCommentModal(false)
          }}
          onSubmit={
            setApplicantComment
          }
          applicantId={person.id}
          comment={person.comment}
        />
      )}

      {showCreateListModal && (
        <CreateListModal
          onCancel={() => setShowCreateListModal(false)}
          onSubmit={(values) => {
            console.log(values);
            setShowCreateListModal(false);
            createList(values);
          }}
          buttonLabel={t("createList")}
          hideCancelBtn={true}
          title={t("fillTheFormToCreateList")}
        />
      )}

    </section>
  );
};

export default SearchCard;
