export const convertFirebaseAuthErrorCodes = (
  errorCode: string,
  t: (key: string) => string,
) => {
  switch (errorCode) {
    case "auth/user-not-found":
      return t("firebaseErrorResponse.userNotFound");
    case "auth/wrong-password":
      return t("firebaseErrorResponse.wrongPassword");
    case "auth/email-already-in-use":
      return t("firebaseErrorResponse.emailInUse");
    case "auth/weak-password":
      return t("firebaseErrorResponse.weakPassword");
    case "auth/invalid-email":
      return t("firebaseErrorResponse.invalidEmail");
    case "auth/expired-action-code":
      return t("firebaseErrorResponse.theLinkHasExpired");
    case "auth/invalid-action-code":
      return t("firebaseErrorResponse.theLinkIsInvalid");
    case "auth/too-many-requests":
      return t("firebaseErrorResponse.tooManyRequests");
    default:
      return t("staticMessage.somethingWentWrong");
  }
};

export const convertRestApiErrorCodes = (
  errorCode: string,
  t: (key: string) => string,
) => {
  switch (errorCode) {
    case "application_not_found":
      return t("restErrorResponses.applicationNotFound");
    case "job_not_available":
      return t("restErrorResponses.jobNotAvailable");
    case "shift_starts_within_12_hours":
      return t("restErrorResponses.shiftStartsWithin12Hours");
    case "already_accepted":
      return t("restErrorResponses.alreadyAccepted");
    case "already_cancelled":
      return t("restErrorResponses.alreadyCancelled");
    case "shift_overlap":
      return t("restErrorResponses.shiftOverlap");
    case "contract_not_found":
      return t("restErrorResponses.contractNotFound");
    case "delete_profile_picture_error":
      return t("restErrorResponses.deleteProfilePictureError");
    case "phone_number_taken":
      return t("restErrorResponses.phoneNumberTaken");
    case "phone_number_already_set":
      return t("restErrorResponses.phoneNumberAlreadySet");
    case "email_not_provided":
      return t("restErrorResponses.emailNotProvided");
    case "phone_number_not_provided":
      return t("restErrorResponses.phoneNumberNotProvided");
    case "phone_number_or_otp_not_provided":
      return t("restErrorResponses.phoneNumberOrOtpNotProvided");
    case "otp_send_error":
      return t("restErrorResponses.otpSendError");
    case "invalid_otp":
      return t("restErrorResponses.invalidOtp");
    case "otp_validation_error":
      return t("restErrorResponses.otpValidationError");
    case "token_expired":
      return t("restErrorResponses.tokenExpired");
    case "email_not_verified":
      return t("restErrorResponses.emailNotVerified");
    case "job_not_found":
      return t("restErrorResponses.jobNotFound");
    case "no_attendance_records":
      return t("restErrorResponses.noAttendanceRecords");
    case "job_title_not_found":
      return t("restErrorResponses.jobTitleNotFound");
    case "job_title_exists":
      return t("restErrorResponses.jobTitleExists");
    case "industry_not_found":
      return t("restErrorResponses.industryNotFound");
    case "industry_exists":
      return t("restErrorResponses.industryExists");
    case "user_not_worked_on_job":
      return t("restErrorResponses.userNotWorkedOnJob");
    case "already_reviewed_job":
      return t("restErrorResponses.alreadyReviewedJob");
    case "start_time_after_end_time":
      return t("restErrorResponses.startTimeAfterEndTime");
    case "shift_longer_than_12_hours":
      return t("restErrorResponses.shiftLongerThan12Hours");
    case "shift_starts_in_past":
      return t("restErrorResponses.shiftStartsInPast");
    case "shift_starts_within_24_hours":
      return t("restErrorResponses.shiftStartsWithin24Hours");
    case "shifts_overlap":
      return t("restErrorResponses.shiftsOverlap");
    case "job_has_applications":
      return t("restErrorResponses.jobHasApplications");
    case "no_images_to_delete":
      return t("restErrorResponses.noImagesToDelete");
    case "no_valid_images_to_delete":
      return t("restErrorResponses.noValidImagesToDelete");
    case "image_not_found":
      return t("restErrorResponses.imageNotFound");
    case "image_deletion_error":
      return t("restErrorResponses.imageDeletionError");
    case "job_already_completed":
      return t("restErrorResponses.jobAlreadyCompleted");
    case "job_already_unattended":
      return t("restErrorResponses.jobAlreadyUnattended");
    case "not_applied":
      return t("restErrorResponses.notApplied");
    case "cannot_complete":
      return t("restErrorResponses.cannotComplete");
    case "cannot_mark_as_unattended":
      return t("restErrorResponses.cannotMarkAsUnattended");
    case "profile_incomplete":
      return t("restErrorResponses.profileIncomplete");
    case "organization_exists":
      return t("restErrorResponses.organizationExists");
    case "organization_not_found":
      return t("restErrorResponses.organizationNotFound");
    case "organization_unit_exists":
      return t("restErrorResponses.organizationUnitExists");
    case "jobs_limit_reached":
      return t("restErrorResponses.jobsLimitReached");
    case "shifts_limit_reached":
      return t("restErrorResponses.shiftsLimitReached");
    case "seats_limit_reached":
      return t("restErrorResponses.seatsLimitReached");
    case "no_usage_found":
      return t("restErrorResponses.noUsageFound");
    case "no_active_subscription":
      return t("restErrorResponses.noActiveSubscription");
    case "organization_units_limit_reached":
      return t("restErrorResponses.organizationUnitsLimitReached");
    case "no_default_payment_method":
      return t("restErrorResponses.noDefaultPaymentMethod");
    case "subscription_update_failed":
      return t("restErrorResponses.subscriptionUpdateFailed");
    case "unique":
      return t("restErrorResponses.unique");
    case "user_already_in_folder":
      return t("userIsAlreadyInList");
    case "not_authorized":
      return t("youNeedToSubscribeToUseThisFeature");
    default:
      return t("staticMessage.somethingWentWrong");
  }
};

export const handleErrors = (error: any, t: (key: string) => string) => {
  if (error?.code.startsWith("auth/")) {
    return convertFirebaseAuthErrorCodes(error?.code, t);
  } else {
    return convertRestApiErrorCodes(error?.response?.data?.code, t);
  }
};
