import React, {useState} from "react";
import cs from "classnames";
import {getTimeBetweenDates} from "../../../lib/helpers/getTimeBetweenDates";
import {useGlobalContext} from "../../../context/GlobalContext/GlobalContext";
import {useTranslation} from "react-i18next";
import verified from "../../../assets/svg/verified-icon-green.svg";
import {PhotoProvider, PhotoView} from "react-image-previewer";
import {CloseButton} from "react-image-previewer/ui";
import ContactModal from "../../../components/shared/ContactModal/ContactModal";

import "./SearchResultSidebar.scss";
import {convertMinsToHrsMins} from "../../../lib/helpers/convertMinsToHrsMins";

interface SkillLevel {
  level: string,
  name: string
}


const SearchResultSidebar = (props: any) => {
  const {person, shiftTotal} = props;
  let selectedLanguage = localStorage.getItem("lang");

  const [applicantStatus, setApplicantStatus] = useState<string>("Personal Details");

  const [openContactModal, setOpenContactModal] = useState(false); // State for ContactModal
  const daysOfTheWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];

  const globalCtx = useGlobalContext();
  const {t} = useTranslation("common");

  const skillLevels: SkillLevel[] = [
    {level: "beginner", name: t("beginner")},
    {level: "intermediate", name: t("intermediate")},
    {level: "advanced", name: t("advanced")},
    {level: "expert", name: t("expert")},
  ];


  const getSkillSource = (skill: any) => {
    if (skill.experiences?.length > 0) {
      const experience = person.experience.find((exp: any) => exp.id === skill.experiences[0]);
      return experience ? `${experience.job_title} ${experience.company_name}` : "";
    } else if (skill.educations?.length > 0) {
      const education = person.education.find((edu: any) => edu.id === skill.educations[0]);
      return education ? `${education.field_of_study} ${education.school_name}` : "";
    }
    return "";
  };

  return (
    <section className="SearchResultSidebar">
      <div className="SearchResultSidebar__header">
        <div className="SearchResultSidebar__header-container">
          <PhotoProvider
            maskOpacity={0.7}
            overlayRender={(props) => {
              return <CloseButton onClick={props.onClose}/>;
            }}
          >
            <PhotoView src={person.profile?.picture_url}>
              <img
                src={person.profile?.picture_url}
                alt="applicants image"
                className="SearchResultSidebar__person-image"
              />
            </PhotoView>
          </PhotoProvider>

          <div className="SearchResultSidebar__header-info">
            <div className="SearchResultSidebar__name-container">
              <h2
                className="SearchResultSidebar__name">{`${person.profile?.first_name} ${person.profile?.last_name}`}</h2>
              {person?.verified && (
                <img
                  src={verified}
                  alt="verified icon"
                  className="SearchResultSidebar__verified-icon"
                />
              )}
              <div className="SearchResultSidebar__review-wrapper">
                &#9733;
                {`${person.profile?.average_rating}(${person.profile?.total_reviews}) `}
              </div>
            </div>
          </div>
        </div>
        <div className="SearchResultSidebar__header-stats">
          <div className="SearchResultSidebar__body-job-stat">
            <div className="SearchResultSidebar__body-job-stat-wrap">
              <div className="SearchResultSidebar__stat-label">
                {t("jobs.applicantSidebar.attendance")}
              </div>
              <div className="SearchResultSidebar__stat-number">
                {person.profile?.attendance_average_rating}
              </div>
            </div>
            <div className="SearchResultSidebar__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`SearchResultSidebar__stat-line-one
                        ${person.profile?.attendance_average_rating === 1 && item === 1 && `SearchResultSidebar__stat-line-one--red`}
                        ${
                    person?.profile
                      ?.attendance_average_rating <= 4 &&
                    person.profile?.attendance_average_rating >
                    1 &&
                    item <=
                    person?.profile
                      ?.attendance_average_rating &&
                    person?.profile
                      ?.attendance_average_rating !== null &&
                    `SearchResultSidebar__stat-line-one--yellow`
                  }
                        ${person.profile?.attendance_average_rating === 5 && `SearchResultSidebar__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
          <div className="SearchResultSidebar__body-job-stat">
            <div className="SearchResultSidebar__body-job-stat-wrap">
              <div className="SearchResultSidebar__stat-label">
                {t("jobs.applicantSidebar.skills")}
              </div>
              <div className="SearchResultSidebar__stat-number">
                {person.profile?.skill_average_rating}
              </div>
            </div>
            <div className="SearchResultSidebar__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`SearchResultSidebar__stat-line-one
                        ${person.profile?.skill_average_rating === 1 && item === 1 && `SearchResultSidebar__stat-line-one--red`}
                        ${
                    person.profile?.skill_average_rating <=
                    4 &&
                    person.profile?.skill_average_rating > 1 &&
                    item <=
                    person.profile?.skill_average_rating &&
                    person.profile?.skill_average_rating !==
                    null &&
                    `SearchResultSidebar__stat-line-one--yellow`
                  }
                        ${person.profile?.skill_average_rating === 5 && `SearchResultSidebar__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
          <div className="SearchResultSidebar__body-job-stat">
            <div className="SearchResultSidebar__body-job-stat-wrap">
              <div className="SearchResultSidebar__stat-label">
                {t("jobs.applicantSidebar.communication")}
              </div>
              <div className="SearchResultSidebar__stat-number">
                {person.profile?.communication_average_rating}
              </div>
            </div>
            <div className="SearchResultSidebar__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`SearchResultSidebar__stat-line-one
                        ${person.profile?.communication_average_rating === 1 && item === 1 && `SearchResultSidebar__stat-line-one--red`}
                        ${
                    person?.profile
                      ?.communication_average_rating <= 4 &&
                    person?.profile
                      ?.communication_average_rating > 1 &&
                    item <=
                    person?.profile
                      ?.communication_average_rating &&
                    person?.profile
                      ?.communication_average_rating !== null &&
                    `SearchResultSidebar__stat-line-one--yellow`
                  }
                        ${person.profile?.communication_average_rating === 5 && `SearchResultSidebar__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
          <div className="SearchResultSidebar__body-job-stat">
            <div className="SearchResultSidebar__body-job-stat-wrap">
              <div className="SearchResultSidebar__stat-label">
                {t("jobs.applicantSidebar.teamWork")}
              </div>
              <div className="SearchResultSidebar__stat-number">
                {person.profile?.teamwork_average_rating}
              </div>
            </div>
            <div className="SearchResultSidebar__stat-line">
              {Array.from([1, 2, 3, 4, 5]).map((item, index) => (
                <div
                  key={index}
                  className={cs(`SearchResultSidebar__stat-line-one
                        ${person.profile?.teamwork_average_rating === 1 && item === 1 && `SearchResultSidebar__stat-line-one--red`}
                        ${
                    person.profile?.teamwork_average_rating <=
                    4 &&
                    person.profile?.teamwork_average_rating >
                    1 &&
                    item <=
                    person?.profile
                      ?.teamwork_average_rating &&
                    person.profile?.teamwork_average_rating !==
                    null &&
                    `SearchResultSidebar__stat-line-one--yellow`
                  }
                        ${person.profile?.teamwork_average_rating === 5 && `SearchResultSidebar__stat-line-one--green`}
                        `)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <section className="SearchResultSidebar__container">
        {person?.selected_shifts && person?.selected_shifts.length > 0 && (
          <div
            onClick={() => setApplicantStatus("Applied Shifts")}
            className={cs(
              "SearchResultSidebar__container-item",
              applicantStatus === "Applied Shifts" &&
              "SearchResultSidebar__container-item--active",
            )}
          >
            {t("jobs.applicantSidebar.appliedShifts")}
          </div>
        )}
        <div
          onClick={() => setApplicantStatus("Personal Details")}
          className={cs(
            "SearchResultSidebar__container-item",
            applicantStatus === "Personal Details" &&
            "SearchResultSidebar__container-item--active",
          )}
        >
          {t("jobs.applicantSidebar.personalDetails.title")}
        </div>
        <div
          onClick={() => setApplicantStatus("Experience")}
          className={cs(
            "SearchResultSidebar__container-item",
            applicantStatus === "Experience" &&
            "SearchResultSidebar__container-item--active",
          )}
        >
          {t("jobs.applicantSidebar.experience.title")}
        </div>
        <div
          onClick={() => setApplicantStatus("Education")}
          className={cs(
            "SearchResultSidebar__container-item",
            applicantStatus === "Education" &&
            "SearchResultSidebar__container-item--active",
          )}
        >
          {t("education")}
        </div>
        <div
          onClick={() => setApplicantStatus("Skills")}
          className={cs(
            "SearchResultSidebar__container-item",
            applicantStatus === "Skills" &&
            "SearchResultSidebar__container-item--active",
          )}
        >
          {t("skills")}
        </div>
      </section>
      {applicantStatus === "Applied Shifts" && (
        <section className="SearchResultSidebar__experience">
          {person?.selected_shifts.map((item: any, index: number) => (
            // <div className="SearchResultSidebar__experience-item" key={index}>
            <div className="ShortTermJobCard__shift-card-body">
              <div>
                <div className="ShortTermJobCard__shift-card-start">
                  {" "}
                  {`${daysOfTheWeek[new Date(item.start_time).getDay()]} ${new Date(item.start_time).toLocaleString("en-GB", {
                    month: "short",
                    day: "numeric"
                  })}`}
                  {new Date(item.end_time).toLocaleString("en-GB", {
                    month: "short",
                    day: "numeric",
                  }) !==
                  new Date(item.start_time).toLocaleString("en-GB", {
                    month: "short",
                    day: "numeric",
                  })
                    ? ` - ${daysOfTheWeek[new Date(item.end_time).getDay()]} ${new Date(item.end_time).toLocaleString("en-GB", {
                      month: "short",
                      day: "numeric"
                    })} `
                    : ""}
                </div>

                <div className="NewJobForm__shift-card-end">
                  {`${new Date(item.start_time).toLocaleString("en-GB", {
                    hour: "numeric",
                    minute: "numeric"
                  })} ${t("jobs.shortTermJobCard.to")} ${new Date(item.end_time).toLocaleString("en-GB", {
                    hour: "numeric",
                    minute: "numeric"
                  })} `}
                </div>
              </div>
              {shiftTotal && (
                <div className="ShortTermJobCard__shift-card-total">
                  {" "}
                  {convertMinsToHrsMins(
                    getTimeBetweenDates(
                      new Date(item.start_time),
                      new Date(item.end_time),
                    ).minutes,
                  )}{" "}
                  total
                </div>
              )}
            </div>
            // </div>
          ))}
        </section>
      )}


      {applicantStatus === "Experience" && (
        <section className="SearchResultSidebar__experience">
          {person.experience &&
            person.experience.map((item: any, index: number) => (
              <div className="SearchResultSidebar__experience-item" key={index}>
                <h2 className="SearchResultSidebar__experience-item-title">
                  {item?.job_title}
                </h2>
                <p>{item?.company_name}</p>
                <div className="SearchResultSidebar__experience-item-date">
                  {`${new Date(item?.start_date).toLocaleString("en-GB", {
                    month: "short",
                    year: "numeric"
                  })} - ${item?.end_date === null ? "Present" : new Date(item?.end_date).toLocaleString("en-GB", {
                    month: "short",
                    year: "numeric"
                  })} `}
                  <span>&middot;</span>
                  {item?.end_date === null ? (
                    <span>
                      {`${
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(),
                          ).days / 365,
                        ) !== 0
                          ? `${Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date()).days / 365)}yr `
                          : ``
                      }`}
                      {`${
                        getTimeBetweenDates(
                          new Date(item?.start_date),
                          new Date(),
                        ).days /
                        365 -
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(),
                          ).days / 365,
                        ) !==
                        0 &&
                        (
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(),
                          ).days /
                          365 -
                          Math.round(
                            getTimeBetweenDates(
                              new Date(item?.start_date),
                              new Date(),
                            ).days / 365,
                          )
                        )
                          .toString()
                          .split(".")[1][0] !== "0"
                          ? ` ${(getTimeBetweenDates(new Date(item?.start_date), new Date()).days / 365 - Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date()).days / 365)).toString().split(".")[1][0]} mos`
                          : ""
                      }`}
                    </span>
                  ) : (
                    <span>
                      {` ${
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(item?.end_date),
                          ).days / 365,
                        ) !== 0
                          ? `${Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date(item?.end_date)).days / 365)}yr `
                          : ``
                      }`}
                      {`${
                        getTimeBetweenDates(
                          new Date(item?.start_date),
                          new Date(item?.end_date),
                        ).days /
                        365 -
                        Math.round(
                          getTimeBetweenDates(
                            new Date(item?.start_date),
                            new Date(item?.end_date),
                          ).days / 365,
                        ) !==
                        0
                          ? ` ${(getTimeBetweenDates(new Date(item?.start_date), new Date(item?.end_date)).days / 365 - Math.round(getTimeBetweenDates(new Date(item?.start_date), new Date(item?.end_date)).days / 365)).toString().split(".")[1][0]} mos`
                          : ""
                      }`}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </section>
      )}
      {applicantStatus === "Personal Details" && (
        <section className="SearchResultSidebar__personal-details">
          <div className="SearchResultSidebar__personal-details-item">
            <p className="SearchResultSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.gender")}
            </p>
            <p className="SearchResultSidebar__personal-details-value">
              {person?.profile.gender === "M" ? "Male" : "Female"}
            </p>
          </div>
          <div className="SearchResultSidebar__personal-details-item">
            <p className="SearchResultSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.email")}
            </p>
            <p className="SearchResultSidebar__personal-details-value">
              {person?.email}
            </p>
          </div>
          <div className="SearchResultSidebar__personal-details-item">
            <p className="SearchResultSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.phoneNumber")}
            </p>
            <p
              className="SearchResultSidebar__personal-details-value"
              onClick={() =>
                person?.phone_number && setOpenContactModal(true)
              }
            >
              {person?.phone_number ? (
                <>
                  {person.phone_number}
                  <i
                    className="fa fa-phone"
                    aria-hidden="true"
                    style={{marginLeft: "10px"}}
                  ></i>
                  <i
                    className="fa fa-whatsapp"
                    aria-hidden="true"
                    style={{color: "#25D366", marginLeft: "10px"}}
                  ></i>
                </>
              ) : (
                t("jobs.applicantSidebar.personalDetails.noPhoneNumber")
              )}
            </p>
          </div>
          <div className="SearchResultSidebar__personal-details-item">
            <p className="SearchResultSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.birthDate")}
            </p>
            <p className="SearchResultSidebar__personal-details-value">
              {" "}
              {new Date(
                person?.profile.date_of_birth,
              ).toLocaleString("en-GB", {
                month: "short",
                year: "numeric",
                day: "numeric",
              })}
            </p>
          </div>
          <div className="SearchResultSidebar__personal-details-item">
            <p className="SearchResultSidebar__personal-details-label">
              {t("jobs.applicantSidebar.personalDetails.location")}
            </p>
            <p className="SearchResultSidebar__personal-details-value">
              {person?.profile.address}
            </p>
          </div>

          <div className="SearchResultSidebar__personal-details-item">
            <p className="SearchResultSidebar__personal-details-label">
              {t("hasDrivingLicense")}
            </p>
            <p className="SearchResultSidebar__personal-details-value">
              {person?.profile.has_driving_license ? t("yes") : t("no")}
            </p>
          </div>


        </section>
      )}
      {applicantStatus === "Education" && (
        <section className="SearchResultSidebar__experience">
          <div className="SearchResultSidebar__personal-details-item">

            <p className="SearchResultSidebar__personal-details-value">
              {person?.education && person.education.length > 0 ? (
                person?.education.map((item: any, index: number) => (
                  <div key={index}>
                    {/*{item.degree} në {item.field_of_study} në{" "}*/}
                    {/*{item.school_name}*/}
                    <div className="SearchResultSidebar__experience-item" key={item.id || 0}>
                      <h2 className="SearchResultSidebar__experience-item-title">
                        {item.degree} në {item.field_of_study}
                      </h2>
                      <p>{item.school_name}</p>
                      <div className="SearchResultSidebar__experience-item-date">
                        {`${new Date(item.start_date).toLocaleString("en-GB", {
                          month: "short",
                          year: "numeric",
                        })} - ${item.end_date === null ? "Present" : new Date(item.end_date).toLocaleString("en-GB", {
                          month: "short",
                          year: "numeric",
                        })}`}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="SearchResultSidebar__experience-item">
                  <div>{t("hasNoEducationYet")}</div>
                </div>
              )}
            </p>

          </div>
        </section>
      )}
      {applicantStatus === "Skills" && (
        <section className="SearchResultSidebar__experience">
          <div className="SearchResultSidebar__personal-details-item">
            <p className="SearchResultSidebar__personal-details-value">
              {person?.skills && person.skills.length > 0 ? (
                person.skills.map((item: any, index: number) => {
                  const skillLevel = skillLevels.find((level) => level.level === item.skill_level);
                  return (
                    <div key={index}>
                      <div className="SearchResultSidebar__experience-item" key={item.id || 0}>
                        <h2 className="SearchResultSidebar__experience-item-title">
                          {item.skill_name}
                        </h2>
                        <p>{t("skillLevel")} : {skillLevel ? skillLevel.name : t("unknownLevel")}</p>
                        <div className="SearchResultSidebar__experience-item-date">
                          {getSkillSource(item)}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="SearchResultSidebar__experience-item">
                  <div>{t("noSkillsYet")}</div>
                </div>
              )}
            </p>
          </div>
        </section>
      )}

      {openContactModal && (
        <ContactModal
          phoneNumber={person?.phone_number}
          onClose={() => setOpenContactModal(false)}
        />
      )}
    </section>
  );
};

export default SearchResultSidebar;
