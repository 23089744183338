import React, {FunctionComponent, useState} from 'react';
import styles from './Enterprise.module.css';
import checkmark from '../../../assets/svg/checkmark.svg';
import sparkles from '../../../assets/svg/sparkles.svg';
import soonTag from '../../../assets/svg/soon-tag.svg';
import ContactModal from "../../../components/shared/ContactModal/ContactModal";

const SlotContainer:FunctionComponent = () => {
  const [openContactModal, setShowContactModal] = useState(false);
  return (
    <div className={styles.slotContainer}>
      <div className={styles.pricingCard}>
        <div className={styles.cardContent}>
          <div className={styles.pricingHeadear}>
            <div className={styles.freeParent}>
              <b className={styles.free}>Enterprise</b>
              <div className={styles.bestForPersonalContainer}>
                <p className={styles.prKorporataQ}>Për korporata që kërkojnë qasje të avancuar në kandidatë dhe rekrutim inteligjent</p>
              </div>
            </div>
            <div className={styles.pricingHeadearChild} />
          </div>
          <div className={styles.buttonmailtoErikpeakjobsa} onClick={() => setShowContactModal(true)}>
            <div className={styles.hrefmailtoErikpeakjobsapp}>Kontakto</div>
          </div>
          <div className={styles.pricingFeatures}>
            <div className={styles.whatYouGet}>Të gjitha funksionet e Business+, plus:</div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Kërkimi i avancuar i kandidatëve (pa postim pune)</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div
                className={styles.whatYouGet1}>Filtrim sipas moshës, gjinisë, përvojës, kualifikimeve dhe vendodhjes</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Akses në të gjithë databazën e personave (Mbi 50,000 persona)</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Ndarje e kandidatëve të preferuar nepër projekte</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Statistika të avansuara mbi përformancën e rekrutimit</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Njoftime të personalizuara për kandidatët e preferuar</div>
            </div>
            <div className={styles.featureRow}>
              <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
              <div className={styles.whatYouGet1}>Akses në funksionet më të fundit në Peakjobs</div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.frame}>
        <div className={styles.aksesTHershm}>Akses të hershëm</div>
      </div>
      <div className={styles.parent}>
        <b className={styles.free}>499.99€</b>
        <div className={styles.muaj}>/muaj</div>
      </div>
      <div className={styles.featureRow5} />
      <div className={styles.featureRow6} />
      <div className={styles.featureRow7}>
        <div className={styles.checkmarkIcon} />
      </div>
      {openContactModal && (
        <ContactModal
          phoneNumber={"+38345276300"}
          onClose={() => setShowContactModal(false)}
          isContactSales={true}
        />
      )}
    </div>);
};
export default SlotContainer;
