import {FunctionComponent} from 'react';
import styles from './Pro.module.css';
import checkmark from '../../../assets/svg/checkmark.svg';
import sparkles from '../../../assets/svg/sparkles.svg';
import soonTag from '../../../assets/svg/soon-tag.svg';
import {useNavigate} from "react-router-dom";

const PricingCard: FunctionComponent = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.pricingCard}>
      <div className={styles.puplar}>
        <div className={styles.mostPopular}>Pako Pro</div>
        <img className={styles.sparklesIcon} alt="" src={sparkles}/>
      </div>
      <div className={styles.cardContent}>
        <div className={styles.pricingHeadear}>
          <div className={styles.freeParent}>
            <b className={styles.free}>Pro</b>
            <div className={styles.bestForPersonalContainer}>
              <p className={styles.prBizneseQ}>Për biznese që duan më shumë dukshmëri dhe kandidatë cilësorë</p>
            </div>
          </div>
          <div className={styles.parent}>
            <b className={styles.free}>59.99€</b>
            <div className={styles.month}>/postim</div>
          </div>
        </div>
        <div
          className={styles.buttonhttpsbusinesspeakjo}
          onClick={() => {
          navigate("/jobs/new-long-term-job");
        }}
        >
          <div className={styles.mostPopular}>Posto Tani</div>
        </div>
        <div className={styles.pricingFeatures}>
          <div className={styles.whatYouGet}>Të gjitha funksionet e Basic, plus:</div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>Sponzorizimi i postimit (Shfaqet ne fillim të listës)</div>
          </div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>Njoftim direkt i kadidatëve potencial në aplikacion</div>
          </div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>Postime në rrjete sociale (+300,000 shikues aktiv)</div>
          </div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>Komente dhe vlerësime për aplikantët</div>
          </div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>Analiza të klikimeve (mosha, gjinia)</div>
          </div>
          <div className={styles.featureRow}>
            <img className={styles.checkmarkIcon} alt="" src={checkmark}/>
            <div className={styles.whatYouGet1}>Analiza e performancës (raporti ndaj klikimeve dhe shikimeve unike)</div>
          </div>
        </div>
      </div>
    </div>);
};

export default PricingCard;
