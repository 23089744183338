import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";

export const useLongTermFormik = (opts: {
  onSubmit?: any;
  initialValues?: any;
}) => {
  const { t } = useTranslation("common");

  const memoizedT = useCallback(t, [t]);
  let duplicateFromLongTermJob = JSON.parse(localStorage.getItem("duplicateFromLongTermJob") || "{}");

  return useFormik({
    initialValues: {
      job_title_text: duplicateFromLongTermJob?.job_title_text,
      ...opts?.initialValues,
      minSalary: duplicateFromLongTermJob.schedule?.monthly_pay_rate_start || "",
      maxSalary: duplicateFromLongTermJob.schedule?.monthly_pay_rate_end || "",
      seats: duplicateFromLongTermJob.seats? duplicateFromLongTermJob.seats : "",
      description: duplicateFromLongTermJob?.description,
      ...opts?.initialValues,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      minSalary: Yup.number(),
      maxSalary: Yup.number(),
      seats: Yup.number()
        .required(memoizedT("jobs.newLongTermJob.jobDetails.seats.required"))
        .min(1),
      description: Yup.string()
        .required(
          memoizedT("jobs.newLongTermJob.jobDetails.description.required"),
        )
        .min(5, memoizedT("jobs.newLongTermJob.jobDetails.description.min"))
        .max(5000, memoizedT("jobs.newLongTermJob.jobDetails.description.max")),
        job_title_text: Yup.string()
            .required(memoizedT("jobs.newLongTermJob.jobTitleRequired"))
            .min(5, memoizedT("jobs.newLongTermJob.jobTitleMin"))
            .max(60, memoizedT("jobs.newLongTermJob.jobTitleMax")),
    }),
    onSubmit: async (values, formikHelpers) => {
      await opts.onSubmit(values, formikHelpers);
    },
  });
};
